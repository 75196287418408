import { truncate } from 'lodash';
import React from 'react';

import { CopyText } from 'components';

type Props = {
  id: string;
};

export const CopyTextId: React.FC<Props> = ({ id }) => (
  <CopyText
    text={id}
    iconColor="disabled"
    labelTextColor="textSecondary"
    label={`#${truncate(id, { length: 10 })}`}
  />
);
