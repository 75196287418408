import { AdditionalOrderColumn, OrdersTab } from 'enums';
import { Orders } from 'features/payout-orders';

export const TraderCompletedOrders: React.FC = () => (
  <Orders
    tab={OrdersTab.Completed}
    additionalColumns={[
      AdditionalOrderColumn.TraderPayment,
      AdditionalOrderColumn.CustomerContacts,
      AdditionalOrderColumn.CustomerRequisites,
      AdditionalOrderColumn.TraderPayment,
    ]}
  />
);
