import { TFunction } from 'i18next';
import { isFunction, omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  MutationFunction,
  useMutation as useMutationReactQuery,
  UseMutationOptions,
  UseMutationResult,
} from 'react-query';
import { toast, Id, ToastOptions } from 'react-toastify';

import { TranslationNamespace } from 'i18n';

const notifierTypeKey = 'notifierType';
const notifierMessagesKey = 'notifierMessages';
const onBeforeMutation = 'onBeforeMutation';
type NotifierType = 'save' | 'remove' | 'execute' | 'none';
const defaultNotifierType: NotifierType = 'save';
type NotifierMessages = {
  loading?: string | null;
  success?: string | null;
  error?: ((error: any) => string | undefined) | string | null;
};
type ExtendOptions = {
  [notifierTypeKey]?: NotifierType;
  [notifierMessagesKey]?: NotifierMessages;
  [onBeforeMutation]?: (data: any) => any;
};

function getNotifierMessages(
  t: TFunction,
  notifierType: NotifierType = defaultNotifierType,
): NotifierMessages {
  if (notifierType === 'none') {
    return null as any as NotifierMessages;
  }
  return {
    loading: t(`${notifierType}.loading`),
    success: t(`${notifierType}.success`),
    error: t(`${notifierType}.error`),
  };
}

function extendOptions<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  t: TFunction,
  options?: Omit<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    'mutationKey' | 'mutationFn'
  > &
    ExtendOptions,
): Partial<
  Omit<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    'mutationKey' | 'mutationFn'
  >
> {
  let toastId: Id;
  const messages =
    options?.[notifierMessagesKey] ||
    getNotifierMessages(t, options?.[notifierTypeKey]);
  return {
    ...(options && omit(options, notifierTypeKey, notifierMessagesKey)),
    onMutate: (
      variables: TVariables,
    ): Promise<TContext | undefined> | TContext | undefined => {
      if (messages?.loading) {
        toastId = toast.loading(messages.loading);
      }
      return options?.onMutate?.(variables);
    },
    onSuccess: (
      data: TData,
      variables: TVariables,
      context: TContext | undefined,
    ): Promise<unknown> | void => {
      if (messages?.success) {
        const options: ToastOptions = {
          type: 'success',
          isLoading: false,
        };
        if (toastId) {
          toast.update(toastId, {
            ...options,
            closeButton: true,
            autoClose: 3000,
            render: messages.success,
          });
        } else {
          toast.success(messages.success, options);
        }
      }
      return options?.onSuccess?.(data, variables, context);
    },
    onError: (
      error: TError,
      variables: TVariables,
      context: TContext | undefined,
    ): Promise<unknown> | void => {
      if (messages?.error) {
        const toastOptions: ToastOptions = {
          type: 'error',
          isLoading: false,
        };
        const message =
          (isFunction(messages.error)
            ? messages.error(error)
            : messages.error) ||
          getNotifierMessages(t, options?.[notifierTypeKey]).error;

        if (toastId) {
          toast.update(toastId, {
            ...toastOptions,
            closeButton: true,
            autoClose: 3000,
            render: message,
          });
        } else {
          toast.error(message, toastOptions);
        }
      }
      return options?.onError?.(error, variables as any as TVariables, context);
    },
  };
}

function getMutationFn<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  mutationFn: MutationFunction<TData, TVariables>,
  options:
    | (Omit<
        UseMutationOptions<TData, TError, TVariables, TContext>,
        'mutationKey' | 'mutationFn'
      > &
        ExtendOptions)
    | undefined,
) {
  return (variables: TVariables) => {
    const formatted = options?.onBeforeMutation
      ? options.onBeforeMutation(variables)
      : variables;
    return mutationFn(formatted);
  };
}

export function useMutation<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  mutationFn: MutationFunction<TData, TVariables>,
  options?: Omit<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    'mutationKey' | 'mutationFn'
  > &
    ExtendOptions,
): UseMutationResult<TData, TError, TVariables, TContext> {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.notifier.http',
  });
  return useMutationReactQuery(
    getMutationFn(mutationFn, options),
    extendOptions(t, options),
  );
}
