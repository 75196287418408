import { AxiosError } from 'axios';
import React, { Fragment, useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';

import { walletsApi } from 'api';
import {
  CopyText,
  DataGrid,
  DataWrapper,
  PageHeader,
  DataGridColumnDefinition,
  WalletDialog,
  Dialog,
  CloseDialogResult,
  dataGridColumns,
} from 'components';
import { NEW_ID } from 'constants/common.constants';
import { StatusCode, QueryKey } from 'enums';
import { useMutation } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { Wallet } from 'types';

export const AdminPlatformWalletsPage: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.assets.platform.wallets',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);

  const queryResult = useQuery(QueryKey.Wallets, walletsApi.getAll);
  const queryClient = useQueryClient();
  const { data: wallets = [] } = queryResult;

  const [detailsDialogProps, setDetailsDialogProps] = useState<{
    open: boolean;
    walletId: string;
  }>({
    open: false,
    walletId: '',
  });

  const [confirmRemoveDialogProps, setConfirmRemoveDialogProps] = useState<{
    open: boolean;
    data?: Wallet;
  }>({
    open: false,
  });

  const { mutate: remove } = useMutation(walletsApi.remove, {
    onSuccess: () => {
      setConfirmRemoveDialogProps({ open: false });
      queryClient.invalidateQueries(QueryKey.Wallets);
    },
    notifierMessages: {
      error: (error: AxiosError) => {
        const status = error?.response?.status;
        if (status === StatusCode.Conflict) {
          return t('remove_error');
        }
        return tCommon('components.notifier.http.remove.error');
      },
    },
  });

  const handleEdit = useCallback(
    ({ id }: { id: string }) =>
      setDetailsDialogProps({
        open: true,
        walletId: id,
      }),
    [],
  );

  const handleCreate = useCallback(
    () =>
      setDetailsDialogProps({
        open: true,
        walletId: NEW_ID,
      }),
    [],
  );

  const handleCloseCreateOrEditDialog = useCallback(
    () =>
      setDetailsDialogProps({
        open: false,
        walletId: '',
      }),
    [],
  );

  const handleRemove = useCallback((data: Wallet) => {
    setConfirmRemoveDialogProps({ open: true, data });
  }, []);

  const handleCloseRemoveDialog = useCallback(
    ({ ok, data }: CloseDialogResult<Wallet>) => {
      if (!ok || !data) {
        setConfirmRemoveDialogProps({ open: false });
        return;
      }
      remove(data);
    },
    [remove],
  );

  const columns = useMemo(
    (): DataGridColumnDefinition<Wallet>[] => [
      {
        header: t('columns.id'),
        valueGetter: (item) => <CopyText text={item.id} iconOnly />,
      },
      {
        header: t('columns.name'),
        valueKey: 'name',
      },
      {
        header: t('columns.address'),
        valueKey: 'address',
      },
      {
        header: t('columns.network'),
        valueKey: 'network',
      },
      dataGridColumns.getActionsColumn({
        handleEdit,
        handleRemove,
      }),
    ],
    [handleEdit, handleRemove, t],
  );

  return (
    <Fragment>
      <PageHeader
        title={t('title')}
        rightContentButton={{ onClick: handleCreate }}
      />
      <DataWrapper queryResult={queryResult}>
        <DataGrid columns={columns} data={wallets} />
      </DataWrapper>
      <WalletDialog
        onClose={handleCloseCreateOrEditDialog}
        {...detailsDialogProps}
      />
      <Dialog
        title={t('wallet_dialog.remove_title')}
        onClose={handleCloseRemoveDialog}
        {...confirmRemoveDialogProps}
      >
        {confirmRemoveDialogProps.data && (
          <div>{confirmRemoveDialogProps.data.name}</div>
        )}
      </Dialog>
    </Fragment>
  );
};
