import i18next from 'i18next';

import { CurrencyExchangeSource, FiatCurrencyExchangeSource } from 'enums';

const getCurrencyExchangeSourceLabel = (source: CurrencyExchangeSource) => {
  const { t } = i18next;
  const key = `currency_exchange_source.${source}`;
  return i18next.exists(key) ? t(key as any) : source;
};

const getFiatCurrencyExchangeSourceLabel = (
  source: FiatCurrencyExchangeSource,
) => {
  const { t } = i18next;
  const key = `fiat_currency_exchange_source.${source}`;
  return i18next.exists(key) ? t(key as any) : source;
};

export const currencyExchangeUtils = {
  getCurrencyExchangeSourceLabel,
  getFiatCurrencyExchangeSourceLabel,
};
