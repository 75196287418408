import { Receipt as ReceiptIcon } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import cx from 'classnames';
import {
  every,
  filter,
  find,
  flatten,
  isEmpty,
  isObject,
  map,
  sortBy,
  toLower,
  uniq,
  values,
} from 'lodash';
import React, { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReceiptValidationType } from 'enums';
import { useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { PayoutOrder } from 'types';
import { formatUtils } from 'utils';

type Props = {
  order: PayoutOrder;
  labelled?: boolean;
};

export const PayoutOrderReceiptsValidation: React.FC<Props> = ({
  order,
  labelled,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders.receipt_validation',
  });
  const { isManager } = useUser();

  const showReceiptsValidation = useMemo(
    () => !isEmpty(order.receiptsValidation) && isManager,
    [isManager, order.receiptsValidation],
  );

  const validationSuccess = useMemo(
    () => every(order.receiptsValidation, (validation) => validation.success),
    [order.receiptsValidation],
  );

  const templateValidations = useMemo(
    () =>
      filter(order.receiptsValidation, (validation) =>
        [
          ReceiptValidationType.Amount,
          ReceiptValidationType.Date,
          ReceiptValidationType.Phone,
          ReceiptValidationType.CardLastDigits,
        ].includes(validation.type),
      ),
    [order.receiptsValidation],
  );

  const stopScamValidations = useMemo(
    () =>
      sortBy(
        filter(order.receiptsValidation, (validation) =>
          [
            ReceiptValidationType.StopScamAmount,
            ReceiptValidationType.StopScamDate,
            ReceiptValidationType.StopScamValid,
          ].includes(validation.type),
        ),
        (validation) => validation.type === ReceiptValidationType.StopScamValid,
      ),
    [order.receiptsValidation],
  );

  const stopScamValidDetailsTypes = useMemo(() => {
    const stopScamValidResult = find(stopScamValidations, {
      type: ReceiptValidationType.StopScamValid,
    });
    if (isObject(stopScamValidResult?.details)) {
      return uniq(
        map(flatten(values(stopScamValidResult?.details)), (details: any) =>
          toLower(details.type),
        ),
      );
    }
    return [];
  }, [stopScamValidations]);

  const renderValidations = useCallback(
    (
      title: string,
      validations: { type: ReceiptValidationType; success: boolean }[],
      className?: string,
    ) =>
      !isEmpty(validations) && (
        <div className={cx('tw-text-xs', className)}>
          <div className="tw-font-bold">{title}</div>
          {map(validations, (validation) => (
            <div key={validation.type}>{`${t(`type.${validation.type}`, {
              defaultValue: validation.type,
            })}: ${formatUtils.formatBoolean(validation.success)}`}</div>
          ))}
        </div>
      ),
    [t],
  );

  const renderTooltipTitle = useCallback(
    () => (
      <Fragment>
        <div className="tw-mb-2 tw-text-sm">{t('title')}</div>
        {renderValidations(t('template_title'), templateValidations, 'tw-mb-2')}
        {renderValidations(t('stop_scam_title'), stopScamValidations)}
        {map(stopScamValidDetailsTypes, (type: string) => (
          <div key={type}>{`- ${t(`stop_scam_verdict.${type}` as any)}`}</div>
        ))}
      </Fragment>
    ),
    [
      t,
      templateValidations,
      renderValidations,
      stopScamValidations,
      stopScamValidDetailsTypes,
    ],
  );

  return (
    <Fragment>
      {showReceiptsValidation && (
        <div className="tw-flex tw-items-center">
          {labelled && (
            <div>
              {validationSuccess ? t('status.success') : t('status.failed')}
            </div>
          )}
          <Tooltip title={renderTooltipTitle()}>
            <ReceiptIcon
              fontSize="small"
              sx={{ ml: labelled ? 1 : 0 }}
              color={validationSuccess ? 'success' : 'error'}
            />
          </Tooltip>
        </div>
      )}
    </Fragment>
  );
};
