import { Delete as DeleteIcon } from '@mui/icons-material';
import { Button, IconButton, Paper, Typography } from '@mui/material';
import { FieldArray, useFormikContext } from 'formik';
import { filter, map } from 'lodash';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { FormControls, TradeMethodSelect } from 'components';
import { TranslationNamespace } from 'i18n';

import { Values } from '../ManageShop';

type Props = {
  fiatCurrencyId?: string;
  crossFiatCurrencyId?: string;
};

export const ShopPayoutTradeMethods: React.FC<Props> = ({
  fiatCurrencyId,
  crossFiatCurrencyId,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.shops.manage',
  });
  const { t: tCommon } = useTranslation();
  const formik = useFormikContext<Values>();

  return (
    <Fragment>
      <Typography variant="subtitle1">
        {`${t('sections.trade_methods.title')} (${tCommon('common.payout')})`}
      </Typography>

      <FieldArray
        name="payoutTradeMethodsConfigs"
        render={(arrayHelpers) => (
          <FormControls>
            {map(formik.values.payoutTradeMethodsConfigs, (tradeMethod, i) => {
              const exclude = map(
                filter(
                  formik.values.payoutTradeMethodsConfigs,
                  (t) => t.tradeMethodId !== tradeMethod.tradeMethodId,
                ),
                (t) => t.tradeMethodId,
              );
              return (
                <Paper sx={{ px: 4, py: 6 }} key={i}>
                  <FormControls>
                    <div className="tw-flex tw-items-start">
                      <TradeMethodSelect
                        fiatCurrencyId={fiatCurrencyId}
                        crossFiatCurrencyId={crossFiatCurrencyId}
                        exclude={exclude}
                        sx={{ mr: 1, flex: 1 }}
                        name={`payoutTradeMethodsConfigs[${i}].tradeMethodId`}
                        label={t('fields.trade_method')}
                      />
                      <IconButton onClick={() => arrayHelpers.remove(i)}>
                        <DeleteIcon color="error" />
                      </IconButton>
                    </div>
                  </FormControls>
                </Paper>
              );
            })}
            <div>
              <Button
                variant="outlined"
                onClick={() => arrayHelpers.push({ tradeMethodId: '' })}
              >
                {t('buttons.add_trade_method')}
              </Button>
            </div>
          </FormControls>
        )}
      />
    </Fragment>
  );
};
