import React from 'react';

import { assetBalanceHistoryApi } from 'api';
import { BalanceHistory } from 'components';
import { QueryKey } from 'enums';
import { usePartialQuery } from 'hooks';

export const TraderBalanceHistoryPage: React.FC = () => {
  const queryResult = usePartialQuery(
    QueryKey.AssetBalanceHistory,
    assetBalanceHistoryApi.getMy,
  );

  return <BalanceHistory queryResult={queryResult} />;
};
