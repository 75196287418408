import {
  Delete as DeleteIcon,
  ManageAccounts as ManageAccountsIcon,
} from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { AxiosError } from 'axios';
import { find, sortBy } from 'lodash';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { generatePath, useNavigate } from 'react-router-dom';

import { usersApi } from 'api';
import {
  CloseDialogResult,
  CopyText,
  DataGrid,
  DataGridColumnDefinition,
  DataWrapper,
  Dialog,
  MailLink,
  QueryFilters,
  RoleLabel,
  StylizedNumber,
  UserStatusLabel,
} from 'components';
import { ERROR_MESSAGE } from 'constants/common.constants';
import { ROUTE_PATH } from 'constants/routes';
import { TIME_INTERVAL } from 'constants/time-interval.constants';
import {
  FilterDefinitionType,
  QueryKey,
  StatusCode,
  UserRole,
  UserStatus,
} from 'enums';
import { useMutation, usePartialQuery, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { AgentInfo, DateFilters, FilterDefinition, User } from 'types';
import { authUtils, userUtils } from 'utils';

type UsersFilters = {
  userId: string;
  role: UserRole;
  status: UserStatus;
} & DateFilters;

export const UserList: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.users.user_list',
  });

  const { isAdmin, isTechOperator } = useUser();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const queryResult = usePartialQuery(QueryKey.Users, usersApi.getAllFiltered, {
    refetchInterval: TIME_INTERVAL.ONE_MINUTE,
    refetchIntervalInBackground: true,
  });

  const [confirmRemoveDialogProps, setConfirmRemoveDialogProps] = useState<{
    open: boolean;
    data?: User;
  }>({
    open: false,
  });

  const { mutate: remove } = useMutation(usersApi.remove, {
    onSuccess: () => {
      setConfirmRemoveDialogProps({ open: false });
      queryClient.invalidateQueries(QueryKey.Users);
      queryClient.invalidateQueries(QueryKey.UsersTraders);
    },
    notifierType: 'remove',
    notifierMessages: {
      error: (error: AxiosError<{ message: string | undefined }>) => {
        const status = error?.response?.status;
        if (
          status === StatusCode.Conflict &&
          error.response?.data?.message === ERROR_MESSAGE.ENTITY_IN_USE
        ) {
          return t('remove_dialog.error.in_use');
        }
      },
    },
  });

  const userEmail = useMemo(() => authUtils.getUserEmail(), []);

  const getAgentName = useCallback(
    (agentId: string) => {
      const agentUser = find(queryResult.data, (user) => user.id === agentId);
      return agentUser?.name || agentId;
    },
    [queryResult],
  );

  const isActionDisabled = useCallback(
    (item: User) => !isAdmin && item.role === UserRole.Admin,
    [isAdmin],
  );

  const handleRemove = useCallback((user: User) => {
    setConfirmRemoveDialogProps({ open: true, data: user });
  }, []);

  const userEditPath = useMemo(() => {
    if (isAdmin) {
      return ROUTE_PATH.ADMIN.USER_DETAILS;
    } else if (isTechOperator) {
      return ROUTE_PATH.TECH_OPERATOR.USER_DETAILS;
    }
  }, [isAdmin, isTechOperator]);

  const settingsPath = useMemo(() => {
    if (isAdmin) {
      return ROUTE_PATH.ADMIN.SETTINGS;
    } else if (isTechOperator) {
      return ROUTE_PATH.TECH_OPERATOR.SETTINGS;
    }
  }, [isAdmin, isTechOperator]);

  const handleEdit = useCallback(
    (user: User) => {
      let path = null;
      if (user.email === userEmail && settingsPath) {
        path = settingsPath;
      } else if (userEditPath) {
        path = generatePath(userEditPath, { id: user.id });
      }

      path && navigate(path);
    },
    [userEmail, userEditPath, settingsPath, navigate],
  );

  const handleCloseRemoveDialog = useCallback(
    ({ ok, data }: CloseDialogResult<User>) => {
      if (ok && data) {
        remove(data);
      } else {
        setConfirmRemoveDialogProps({ open: false });
      }
    },
    [remove],
  );

  const columns = useMemo(
    (): DataGridColumnDefinition<User>[] => [
      {
        header: t('table.columns.id'),
        valueGetter: (item) => <CopyText iconOnly text={item.id} />,
      },
      {
        header: t('table.columns.name'),
        valueGetter: (item) => (
          <>
            <div>{item.name}</div>
            <div>
              <MailLink email={item.email} />
            </div>
          </>
        ),
      },
      {
        header: t('table.columns.role'),
        valueGetter: (item) => <RoleLabel role={item.role} />,
      },
      {
        header: t('table.columns.status'),
        valueGetter: (item) => <UserStatusLabel status={item.status} />,
      },
      {
        header: t('table.columns.platform_wallet'),
        valueGetter: (item) =>
          item?.platformWalletId ? (
            <div>
              <div>{item?.platformWallet.name}</div>
              <div>{item?.platformWallet.address}</div>
              <div>{item?.platformWallet.network}</div>
            </div>
          ) : (
            ''
          ),
      },
      {
        header: t('table.columns.agents'),
        multiValueRenderer: {
          itemsGetter: (item) => item.agents,
          valueGetter: (agent: AgentInfo) => (
            <div key={agent.userId} className="tw-flex">
              <span className="tw-pr-2">{getAgentName(agent.userId)}</span>
              <StylizedNumber
                value={agent.payinCompensationPercentage || 0}
                unit="%"
              />
              <span className="tw-px-1">{'/'}</span>
              <StylizedNumber
                value={agent.payoutCompensationPercentage || 0}
                unit="%"
              />
            </div>
          ),
        },
      },
      {
        header: t('table.columns.actions'),
        valueGetter: (item) => (
          <Fragment>
            <IconButton
              color="primary"
              disabled={isActionDisabled(item)}
              onClick={() => handleEdit(item)}
            >
              <ManageAccountsIcon />
            </IconButton>
            <IconButton
              color="error"
              disabled={item.email === userEmail || isActionDisabled(item)}
              onClick={() => handleRemove(item)}
            >
              <DeleteIcon />
            </IconButton>
          </Fragment>
        ),
      },
    ],
    [userEmail, handleEdit, handleRemove, isActionDisabled, getAgentName, t],
  );

  const users = useMemo(
    () => sortBy(queryResult.data, (user) => user.email !== userEmail),
    [queryResult.data, userEmail],
  );

  const filtersDefinitions: FilterDefinition<UsersFilters>[] = useMemo(
    () => [
      {
        label: t('filters.user_id'),
        name: 'userId',
        type: FilterDefinitionType.Text,
        format: 'uuid',
      },
      {
        label: t('filters.role'),
        name: 'role',
        type: FilterDefinitionType.Enum,
        enum: UserRole,
        getDisplayName: userUtils.getRoleLabel,
      },
      {
        label: t('filters.status'),
        name: 'status',
        type: FilterDefinitionType.Enum,
        enum: UserStatus,
        getDisplayName: userUtils.getStatusLabel,
      },
    ],
    [t],
  );

  return (
    <Fragment>
      <QueryFilters filtersDefinitions={filtersDefinitions} />
      <DataWrapper
        queryResult={queryResult}
        ignoreState={{ refetchLoading: true }}
      >
        <DataGrid columns={columns} data={users} />
      </DataWrapper>
      <Dialog
        title={t('remove_dialog.title')}
        onClose={handleCloseRemoveDialog}
        {...confirmRemoveDialogProps}
      >
        <Fragment>
          {confirmRemoveDialogProps.data && (
            <div>{confirmRemoveDialogProps.data.email}</div>
          )}
        </Fragment>
      </Dialog>
    </Fragment>
  );
};
