import { find, map, toLower } from 'lodash';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { usersApi } from 'api';
import { userAuditApi } from 'api/user-audit.api';
import {
  CopyText,
  CrudTable,
  DataGridColumnDefinition,
  Json,
  PageHeader,
  QueryFilters,
  dataGridColumns,
} from 'components';
import { FilterDefinitionType, HttpMethod, QueryKey } from 'enums';
import { usePartialQuery } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { AuditFilters, FilterDefinition, UserAudit } from 'types';

export const AuditPage: React.FC = () => {
  // TODO: move tkeys
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.audit',
  });

  const queryResult = usePartialQuery(
    QueryKey.UserAudit,
    userAuditApi.getAllPaginated,
  );

  const queryResultUsers = useQuery(QueryKey.Users, usersApi.getAll);

  const columns = useMemo(
    (): DataGridColumnDefinition<UserAudit>[] => [
      dataGridColumns.getIdColumn(),
      {
        header: t('fields.user'),
        valueGetter: (item) => item.user?.name,
      },
      {
        header: t('fields.request'),
        valueGetter: (item) => (
          <CopyText
            label={`[${toLower(item.method)}] ${item.url}`}
            text={item.url}
          />
        ),
        valueClassName: 'tw-min-w-[240px] tw-break-all',
      },
      {
        header: t('fields.body'),
        valueGetter: (item) => <Json text={item.body} />,
        valueClassName: 'tw-w-[240px]',
      },
      {
        header: t('fields.ip'),
        valueKey: 'ip',
        valueClassName: 'tw-w-[240px]',
      },
      {
        header: t('fields.user_agent'),
        valueKey: 'userAgent',
        valueClassName: 'tw-w-[240px]',
      },
    ],
    [t],
  );

  const filtersDefinitions: FilterDefinition<AuditFilters>[] = useMemo(
    () => [
      {
        label: t('filters.id'),
        name: 'id',
        type: FilterDefinitionType.Text,
        format: 'uuid',
      },
      {
        label: t('filters.user'),
        name: 'userId',
        type: FilterDefinitionType.User,
        users: queryResultUsers.data,
        getDisplayName: (traderId: string) =>
          find(queryResultUsers.data, { id: traderId })?.name,
      },
      {
        label: t('filters.url'),
        name: 'url',
        type: FilterDefinitionType.Text,
      },
      {
        label: t('filters.method'),
        name: 'method',
        type: FilterDefinitionType.Select,
        options: map(
          [HttpMethod.POST, HttpMethod.PATCH, HttpMethod.DELETE],
          (value) => ({
            value,
            label: toLower(value),
          }),
        ),
        getDisplayName: (value: string) => toLower(value),
      },
      {
        label: t('filters.body'),
        name: 'body',
        type: FilterDefinitionType.Text,
      },
      {
        label: t('filters.ip'),
        name: 'ip',
        type: FilterDefinitionType.Text,
      },
      {
        label: t('filters.user_agent'),
        name: 'userAgent',
        type: FilterDefinitionType.Text,
      },
    ],
    [queryResultUsers.data, t],
  );

  return (
    <Fragment>
      <PageHeader title={t('title')} />
      <QueryFilters withCommon filtersDefinitions={filtersDefinitions} />
      <CrudTable queryResult={queryResult} columns={columns} paginated />
    </Fragment>
  );
};
