import { FormControlLabel, Switch } from '@mui/material';
import React, {
  FC,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { tradersApi } from 'api';
import { QueryKey, TraderWorkStatus } from 'enums';
import { useMutation } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { Trader } from 'types';

interface Props {
  trader?: Trader;
}

export const TraderWorkStatusSelect: FC<Props> = ({ trader }) => {
  const { t } = useTranslation(TranslationNamespace.Trader, {
    keyPrefix: 'layout.navbar.work_status',
  });

  const queryClient = useQueryClient();
  const { mutate: updateTrader } = useMutation(tradersApi.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKey.MyInfo);
    },
  });

  const [status, setStatus] = useState(
    trader?.workStatus ?? TraderWorkStatus.Inactive,
  );
  const statusActive = useMemo(
    () => status === TraderWorkStatus.Active,
    [status],
  );

  const handleStatusChange = useCallback(
    (event: SyntheticEvent, checked: boolean) => {
      const workStatus = checked
        ? TraderWorkStatus.Active
        : TraderWorkStatus.Inactive;

      setStatus(workStatus);

      updateTrader({
        data: {
          workStatus,
        },
      });
    },
    [updateTrader],
  );

  return (
    <FormControlLabel
      control={<Switch checked={statusActive} onChange={handleStatusChange} />}
      label={t(status)}
      labelPlacement="start"
    />
  );
};
