import axios from 'axios';

import { env } from 'config';
import { UserRole } from 'enums';
import { PaginatedData, QueryParams, Requisites } from 'types';

const url = `${env.apiUrl}/requisites`;

const getAllForTraderAsRole =
  (role: UserRole) =>
  async (traderId: string): Promise<Requisites[]> =>
    (await axios.get(`${url}/${role}/trader/${traderId}`)).data;

const getAll = async (
  params: QueryParams,
): Promise<PaginatedData<Requisites>> =>
  (await axios.get(url, { params })).data;

const getForGroupAsRole =
  (role: UserRole) =>
  async (groupId: string): Promise<Requisites[]> =>
    (await axios.get(`${url}/${role}/group/${groupId}`)).data;

const getAllAsOperator = async (
  params: QueryParams,
): Promise<PaginatedData<Requisites>> =>
  (await axios.get(`${url}/operator`, { params })).data;

const getAllAsOperatorArchived = async (
  params: QueryParams,
): Promise<PaginatedData<Requisites>> =>
  (await axios.get(`${url}/operator/archived`, { params })).data;

const getAllMyPaginated = async (
  params: QueryParams,
): Promise<PaginatedData<Requisites>> =>
  (await axios.get(`${url}/my/paginated`, { params })).data;

const getAllMy = async (): Promise<Requisites[]> =>
  (await axios.get(`${url}/my`)).data;

const create = async (requisites: Partial<Requisites>): Promise<Requisites> =>
  (await axios.post(url, requisites)).data;

const update = async ({
  id,
  requisites,
}: {
  id: string;
  requisites: Partial<Requisites>;
}): Promise<Requisites> => (await axios.patch(`${url}/${id}`, requisites)).data;

const updateOrderAutomation = async ({
  id,
  requisites,
}: {
  id: string;
  requisites: Partial<Requisites>;
}): Promise<Requisites> =>
  (await axios.patch(`${url}/${id}/order-automation`, requisites)).data;

const updateStatus = async ({
  id,
  requisites,
}: {
  id: string;
  requisites: Partial<Requisites>;
}): Promise<Requisites> =>
  (await axios.patch(`${url}/${id}/status`, requisites)).data;

const unblock = async (id: string): Promise<Requisites> =>
  (await axios.patch(`${url}/${id}/unblock`)).data;

const findOne = async (id: string): Promise<Requisites> =>
  (await axios.get(`${url}/${id}`)).data;

const remove = async (id: string): Promise<Requisites> =>
  (await axios.delete(`${url}/${id}`)).data;

const archive = async (id: string): Promise<Requisites> =>
  (await axios.post(`${url}/${id}/archive`)).data;

const restore = async (id: string): Promise<Requisites> =>
  (await axios.post(`${url}/${id}/restore`)).data;

const getAllArchived = async (
  params: QueryParams,
): Promise<PaginatedData<Requisites>> =>
  (await axios.get(`${url}/archived`, { params })).data;

export const requisitesApi = {
  getAllForTraderAsRole,
  getAllArchived,
  getAll,
  getForGroupAsRole,
  getAllAsOperator,
  getAllAsOperatorArchived,
  getAllMyPaginated,
  getAllMy,
  create,
  update,
  updateOrderAutomation,
  findOne,
  remove,
  updateStatus,
  unblock,
  archive,
  restore,
};
