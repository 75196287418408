import { AdditionalOrderColumn, OrdersTab } from 'enums';

import { ManageOrdersList } from '../ManageOrderList';

export const CompletedOrders: React.FC = () => (
  <ManageOrdersList
    tab={OrdersTab.Completed}
    additionalColumns={[
      AdditionalOrderColumn.TraderPayment,
      AdditionalOrderColumn.CustomerRequisites,
      AdditionalOrderColumn.Validation,
    ]}
  />
);
