import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';

import { payoutOrdersFilesValidationApi } from 'api';
import {
  CrudTable,
  CrudTableActionType,
  DataGridColumnDefinition,
  dataGridColumns,
} from 'components';
import { QueryKey } from 'enums';
import { useMutation } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { TradeMethodPayoutPDFTemplate } from 'types';

import { PayoutPDFtemplateDetailsDialog } from './PayoutPDFTemplateDetailsDialog';

type Props = {
  tradeMethodId: string;
};

export const PayoutPDFTemplates: React.FC<Props> = ({ tradeMethodId }) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.trade_methods.files_validation',
  });

  const queryKey = useMemo(
    () => [QueryKey.TradeMethodPayoutPDFTemplates, tradeMethodId],
    [tradeMethodId],
  );
  const queryResult = useQuery<TradeMethodPayoutPDFTemplate[]>(queryKey, () =>
    payoutOrdersFilesValidationApi.pdfTemplates.findAllForTradeMethod(
      tradeMethodId!,
    ),
  );

  const { mutate: remove } = useMutation(
    payoutOrdersFilesValidationApi.pdfTemplates.remove,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
      notifierType: 'remove',
    },
  );

  const columns = useMemo(
    (): DataGridColumnDefinition<TradeMethodPayoutPDFTemplate>[] => [
      dataGridColumns.getIdColumn(),
      {
        header: t('columns.template'),
        valueKey: 'template',
      },
    ],
    [t],
  );

  return (
    <CrudTable
      columns={columns}
      queryResult={queryResult}
      actions={[
        {
          type: CrudTableActionType.Details,
          renderDialog: PayoutPDFtemplateDetailsDialog,
        },
        {
          type: CrudTableActionType.Remove,
          onRemove: (item, { close }) => remove(item.id, { onSuccess: close }),
        },
      ]}
    />
  );
};
