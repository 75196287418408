import { Block as BlockIcon } from '@mui/icons-material';
import { FormControl, FormLabel } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { RequisitesStatus, RequisitesStatusDetails } from 'enums';
import { TranslationNamespace } from 'i18n';
import { formatUtils } from 'utils';

type Props = {
  status: RequisitesStatus;
  statusDetails?: RequisitesStatusDetails;
  blockedAt?: string;
};

export const RequisitesBlockedStatusLabel: React.FC<Props> = ({
  status,
  statusDetails,
  blockedAt,
}) => {
  const { t } = useTranslation();

  const { t: tFields } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.requisites.requisites_details.fields',
  });

  if (status !== RequisitesStatus.Blocked) {
    return null;
  }

  return (
    <FormControl sx={{ py: 2 }}>
      <FormLabel>{tFields('status')}</FormLabel>
      <div className={'tw-flex tw-items-center tw-mt-1'}>
        <BlockIcon color="error" sx={{ mr: 2 }} fontSize="small" />
        {`${t('requisites_status.blocked')}: `}
        {t(
          `requisites_status_details.${
            statusDetails as RequisitesStatusDetails
          }`,
        )}
        {` (${formatUtils.formatDate(blockedAt)})`}
      </div>
    </FormControl>
  );
};
