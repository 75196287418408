import { Button, Stack } from '@mui/material';
import { isEmpty, map } from 'lodash';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult } from 'react-query';

import { orderAutomationApi } from 'api';
import {
  OrderAutomationStatusLabel,
  CopyText,
  DescriptionRow,
  DateLabel,
  RequisitesInfo,
  Dialog,
  ParsingMethodsMultipleForm,
} from 'components';
import { OrderAutomationStatus } from 'enums';
import { useMutation, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { OrderAutomation, PaginatedData } from 'types';
import { formatUtils } from 'utils';

type Props = {
  queryResult: UseQueryResult<PaginatedData<OrderAutomation>>;
  orderAutomation: OrderAutomation;
  onClose: () => void;
};

export const OrderAutomationDetails: React.FC<Props> = ({
  queryResult,
  orderAutomation,
  onClose,
}: Props) => {
  const { user, isAdmin, isTechOperator } = useUser();

  const [isParsingMethodsDialogOpen, setParsingMethodsDialogOpen] =
    useState(false);

  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.order_automation',
  });
  const { t: tColumns } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.order_automation.table.columns',
  });
  const { t: tParsingMethods } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'features.parsing_methods',
  });

  const bank = useMemo(
    () => orderAutomation.requisites?.bank || orderAutomation.bank,
    [orderAutomation],
  );

  const onAddParsingMethodsDialog = useCallback(() => {
    setParsingMethodsDialogOpen(true);
  }, []);

  const onCloseParsingMethodsDialog = useCallback(() => {
    setParsingMethodsDialogOpen(false);
  }, []);

  const { mutate: updateOrderAutomation } = useMutation(
    orderAutomationApi.updateAsRole(user.role),
    {
      onSuccess: () => {
        queryResult.refetch();
        onClose();
      },
    },
  );

  const handleParsingMethodCreateSuccess = useCallback(() => {
    setParsingMethodsDialogOpen(false);
    updateOrderAutomation({
      id: orderAutomation.id,
      data: { status: OrderAutomationStatus.TemplateErrorHandled },
    });
  }, [orderAutomation, updateOrderAutomation]);

  return (
    <Stack direction="column" spacing={3}>
      <DescriptionRow
        title={tColumns('created_at')}
        value={
          <DateLabel>
            {formatUtils.formatDate(orderAutomation.createdAt)}
          </DateLabel>
        }
      />
      <DescriptionRow
        title={tColumns('id')}
        value={orderAutomation.id}
        canCopy
      />
      <DescriptionRow
        title={tColumns('status')}
        value={<OrderAutomationStatusLabel status={orderAutomation.status} />}
      />
      {orderAutomation.conflictedOrdersIds && (
        <DescriptionRow
          title={tColumns('conflicted_orders')}
          value={map(orderAutomation.conflictedOrdersIds, (id) => (
            <CopyText text={id} key={id} />
          ))}
        />
      )}
      {orderAutomation.order?.id && (
        <DescriptionRow
          title={tColumns('order')}
          value={<CopyText text={orderAutomation.order.id} />}
        />
      )}
      {orderAutomation.order?.shop && (
        <DescriptionRow
          title={tColumns('shop')}
          value={
            <Fragment>
              <CopyText text={orderAutomation.order.shop.id} />
              <div>{orderAutomation.order.shop.name}</div>
            </Fragment>
          }
        />
      )}
      {orderAutomation.order?.merchant?.user && (
        <DescriptionRow
          title={tColumns('merchant')}
          value={
            <Fragment>
              <CopyText text={orderAutomation.order.merchant.user.id} />
              <div>{orderAutomation.order.merchant.user.name}</div>
            </Fragment>
          }
        />
      )}
      {orderAutomation.trader && (
        <DescriptionRow
          title={tColumns('trader')}
          value={
            <Fragment>
              <CopyText text={orderAutomation.trader.id} />
              <div>{orderAutomation.trader.user?.name}</div>
            </Fragment>
          }
        />
      )}
      {orderAutomation.requisitesGroup && (
        <DescriptionRow
          title={tColumns('requisites_group')}
          value={
            <Fragment>
              <CopyText text={orderAutomation.requisitesGroup.id} />
              <div>{orderAutomation.requisitesGroup.name}</div>
            </Fragment>
          }
        />
      )}
      {orderAutomation.requisites && (
        <DescriptionRow
          title={tColumns('requisites')}
          value={<RequisitesInfo requisite={orderAutomation.requisites} />}
        />
      )}
      {bank?.id && (
        <DescriptionRow
          title={tColumns('bank')}
          value={
            <Fragment>
              <CopyText text={bank.id} />
              <div>{bank.name}</div>
            </Fragment>
          }
        />
      )}
      {orderAutomation.requisites?.paymentType?.id && (
        <DescriptionRow
          title={tColumns('payment_type')}
          value={
            <Fragment>
              <CopyText text={orderAutomation.requisites.paymentType.id} />
              <div>{orderAutomation.requisites.paymentType.name}</div>
            </Fragment>
          }
        />
      )}
      {orderAutomation.automationSourceName && (
        <DescriptionRow
          title={tColumns('automation_sources')}
          value={<CopyText text={orderAutomation.automationSourceName} />}
        />
      )}
      {orderAutomation.notificationType && (
        <DescriptionRow
          title={tColumns('notification_type')}
          value={
            <div>
              {t(`notification_types.${orderAutomation.notificationType}`)}
            </div>
          }
        />
      )}
      {orderAutomation.parsingMethod?.id && (
        <DescriptionRow
          title={tColumns('parsing_method')}
          value={
            <Fragment>
              <CopyText text={orderAutomation.parsingMethod.id} />
              <div>{orderAutomation.parsingMethod.template}</div>
            </Fragment>
          }
        />
      )}
      {orderAutomation.parsingMethodsIds && (
        <DescriptionRow
          title={tColumns('parsing_method_ids')}
          value={map(orderAutomation.parsingMethodsIds, (id) => (
            <CopyText text={id} key={id} />
          ))}
        />
      )}
      {!isEmpty(orderAutomation.parsedParams) && (
        <DescriptionRow
          title={tColumns('parsed_params')}
          value={
            <div className="tw-table-auto">
              {map(orderAutomation.parsedParams, (value, key) => (
                <div className="tw-table-row" key={key}>
                  <div className="tw-table-cell">{key}</div>
                  <div className="tw-table-cell tw-pl-4 tw-break-all">
                    {value}
                  </div>
                </div>
              ))}
            </div>
          }
        />
      )}
      {orderAutomation.status === OrderAutomationStatus.TemplateError &&
        (isAdmin || isTechOperator) && (
          <Button
            size="medium"
            variant="outlined"
            color="primary"
            sx={{ mt: '2' }}
            onClick={onAddParsingMethodsDialog}
          >
            {t('details.template_button')}
          </Button>
        )}
      <Dialog
        title={tParsingMethods('details_form.title.create')}
        open={isParsingMethodsDialogOpen}
        onClose={onCloseParsingMethodsDialog}
        maxWidth="sm"
        modal
      >
        <ParsingMethodsMultipleForm
          bankId={bank?.id}
          message={orderAutomation.text}
          onSuccess={handleParsingMethodCreateSuccess}
        />
      </Dialog>
    </Stack>
  );
};
