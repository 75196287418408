import {
  Delete as DeleteIcon,
  HelpOutlineOutlined as HelpOutlineOutlinedIcon,
} from '@mui/icons-material';
import { Button, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { FieldArray, useFormikContext } from 'formik';
import { filter, find, map } from 'lodash';
import React, { ChangeEvent, Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FormControls,
  FormikNumericField,
  FormikTextField,
  PaymentTypeSelect,
} from 'components';
import { TranslationNamespace } from 'i18n';
import { FormField, FormFieldOverride, PaymentType } from 'types';

import { HiddenField } from './HiddenField';
import { Values } from '../ManageShop';

type Props = {
  paymentTypes: PaymentType[];
};

export const CustomerFieldsPayin: React.FC<Props> = ({ paymentTypes }) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.shops.manage',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);

  const formik = useFormikContext<Values>();

  const handlePaymentTypeSelectChange = useCallback(
    (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      fieldName: string,
    ) => {
      formik.handleChange(e);

      const value = e.target.value;
      const customerFields = find(paymentTypes, {
        id: value,
      })?.customerFields;

      formik.setFieldValue(
        fieldName,
        map<FormField, FormFieldOverride>(customerFields, (field) => ({
          name: field.name,
          hidden: !!field.hidden,
          pattern: field.pattern,
          patternExample: field.patternExample,
          maxLength: field.maxLength,
        })),
      );
    },
    [formik, paymentTypes],
  );

  const getFieldByName = useCallback(
    (paymentTypeId: string, fieldName: string) => {
      const paymentType = find(paymentTypes, { id: paymentTypeId });
      return find(paymentType?.customerFields, { name: fieldName });
    },
    [paymentTypes],
  );

  const getFieldNameLabel = useCallback(
    (fieldName: string, field?: FormField) => {
      if (!field) {
        return fieldName;
      }
      return `${tCommon(`order_form_fields.${field.type}`, {
        defaultValue: field.type,
      })} (${field.name})`;
    },
    [tCommon],
  );

  return (
    <Fragment>
      <Typography variant="subtitle1">
        {t('sections.customer_fields_payin.title')}
        <Tooltip title={t('sections.customer_fields_payin.description')}>
          <HelpOutlineOutlinedIcon
            fontSize="small"
            color="secondary"
            sx={{ ml: 1 }}
          />
        </Tooltip>
      </Typography>

      <FieldArray
        name="configs"
        render={(arrayHelpers) => (
          <FormControls>
            {map(formik.values.configs, (config, i) => {
              const existing = map(
                formik.values.configs,
                (c) => c.paymentTypeId,
              );
              const availablePaymentTypes = filter(
                paymentTypes,
                (t: PaymentType) =>
                  !existing.includes(t.id) || config.paymentTypeId === t.id,
              );
              return (
                <Paper sx={{ px: 4, py: 6 }} key={i}>
                  <FormControls>
                    <div className="tw-flex tw-items-start">
                      <PaymentTypeSelect
                        sx={{ mr: 1, flex: 1 }}
                        name={`configs[${i}].paymentTypeId`}
                        paymentTypes={availablePaymentTypes}
                        onChange={(e) =>
                          handlePaymentTypeSelectChange(
                            e,
                            `configs[${i}].customerFields`,
                          )
                        }
                        noneOption
                      />
                      <IconButton onClick={() => arrayHelpers.remove(i)}>
                        <DeleteIcon color="error" />
                      </IconButton>
                    </div>
                    <div>
                      <FieldArray name={`configs[${i}].customerFields`}>
                        {() =>
                          map(
                            formik.values.configs[i].customerFields,
                            (item, j) => {
                              const originalField = getFieldByName(
                                formik.values.configs[i].paymentTypeId,
                                item.name,
                              );
                              return (
                                <div key={item.name}>
                                  <HiddenField
                                    name={`configs[${i}].customerFields[${j}].hidden`}
                                    label={getFieldNameLabel(
                                      item.name,
                                      originalField,
                                    )}
                                  />

                                  {(originalField?.pattern ||
                                    originalField?.maxLength) && (
                                    <Typography
                                      sx={{ my: 2 }}
                                      color="textSecondary"
                                      variant="subtitle2"
                                    >
                                      {t('overriding.description')}
                                    </Typography>
                                  )}

                                  {originalField?.pattern && (
                                    <>
                                      <FormikTextField
                                        sx={{ my: 3 }}
                                        name={`configs[${i}].customerFields[${j}].pattern`}
                                        label={t(
                                          'overriding.fields.pattern.label',
                                        )}
                                        fullWidth
                                      />
                                      <FormikTextField
                                        name={`configs[${i}].customerFields[${j}].patternExample`}
                                        label={t(
                                          'overriding.fields.pattern_example.label',
                                        )}
                                        fullWidth
                                      />
                                    </>
                                  )}
                                  {originalField?.maxLength && (
                                    <FormikNumericField
                                      sx={{ my: 3 }}
                                      name={`configs[${i}].customerFields[${j}].maxLength`}
                                      label={t(
                                        'overriding.fields.max_length.label',
                                      )}
                                      fullWidth
                                    />
                                  )}
                                </div>
                              );
                            },
                          )
                        }
                      </FieldArray>
                    </div>
                  </FormControls>
                </Paper>
              );
            })}
            <div>
              <Button
                variant="outlined"
                onClick={() => arrayHelpers.push({ paymentTypeId: '' })}
              >
                {t('buttons.add_rule')}
              </Button>
            </div>
          </FormControls>
        )}
      />
    </Fragment>
  );
};
