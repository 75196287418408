import { AdditionalOrderColumn, OrdersTab } from 'enums';

import { ManageOrdersList } from '../ManageOrderList';

export const CancelledOrders: React.FC = () => (
  <ManageOrdersList
    tab={OrdersTab.Cancelled}
    additionalColumns={[
      AdditionalOrderColumn.CustomerRequisites,
      AdditionalOrderColumn.CancelReason,
      AdditionalOrderColumn.Validation,
      AdditionalOrderColumn.TraderPayment,
    ]}
  />
);
