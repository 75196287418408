import styled from '@emotion/styled';
import { AccountBalanceWallet as AccountBalanceWalletIcon } from '@mui/icons-material';
import { Chip, ChipProps } from '@mui/material';
import { rgba } from 'polished';
import React, { Fragment, useMemo } from 'react';

import { CopyText, Money } from 'components';
import { PayinOrder, Order } from 'types';
import { orderUtils } from 'utils';

const Label = styled(Chip)<ChipProps>`
  background: ${(props) => rgba(props.theme.palette.success.main, 0.1)};
  border: 0;
`;

type Props = {
  order: Order;
};

export const OrderAmountColumn: React.FC<Props> = ({ order }) => {
  const payinOrder = useMemo(() => order as PayinOrder, [order]);
  return (
    <Fragment>
      <div className="tw-ml-2">
        <CopyText
          text={String(order.amount)}
          label={
            <span className="tw-flex">
              {payinOrder.previousAmount && `${payinOrder.previousAmount} → `}
              <Money
                value={order.amount}
                fiatCurrencyId={order.fiatCurrencyId}
                symbol
              />
            </span>
          }
          iconColor="disabled"
        />
      </div>
      {orderUtils.isCrossFiatPayment(order) && (
        <Fragment>
          <div className="tw-ml-2">
            <CopyText
              text={String(order.crossFiatCurrencyAmount)}
              label={
                <span className="tw-flex">
                  {'~'}
                  <Money
                    value={order.crossFiatCurrencyAmount}
                    fiatCurrencyId={order.crossFiatCurrencyId}
                    symbol
                  />
                </span>
              }
              iconColor="disabled"
            />
          </div>
        </Fragment>
      )}
      <div className="tw-flex tw-items-end">
        <Label
          label={
            <div>
              <AccountBalanceWalletIcon color="success" fontSize="small" />
              <Money
                value={order.assetCurrencyAmount}
                assetCurrencyId={order.assetCurrencyId}
                symbol
              />
            </div>
          }
          size="small"
          variant="outlined"
        />
        <CopyText
          className="tw-inline"
          text={order.assetCurrencyAmount.toString()}
          iconOnly
          iconColor="disabled"
        />
      </div>
    </Fragment>
  );
};
