import { AxiosError } from 'axios';
import { FormikHelpers, Formik } from 'formik';
import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import * as Yup from 'yup';

import { internalWalletsApi } from 'api';
import {
  CloseFormikDialogResult,
  Dialog,
  DialogProps,
  FormControls,
  FormikSelect,
} from 'components';
import { QueryKey } from 'enums';
import { useMutation, useUserContext } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { InternalWallet } from 'types';
import { formUtils, validationUtils } from 'utils';

type Values = Required<Pick<InternalWallet, 'p2pProviderId'>>;

type Props = DialogProps<InternalWallet>;

export const P2PProviderWalletDialog: React.FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.internal_wallets.p2p_provider_wallet_dialog',
  });

  const { t: tCommon } = useTranslation();

  const { p2pProviders } = useUserContext();

  const queryClient = useQueryClient();

  const initialValues: Values = useMemo(
    () => ({
      p2pProviderId: '',
      shopId: '',
    }),
    [],
  );

  const p2pProvidersOptions = useMemo(
    () => formUtils.getOptions(p2pProviders || []),
    [p2pProviders],
  );

  const mutationCreate = useMutation<InternalWallet, AxiosError, string>(
    internalWalletsApi.createP2PProvider,
  );

  const handleSubmit = useCallback(
    (values: Values, formikHelpers: FormikHelpers<Values>) => {
      const options = {
        onSuccess: () => {
          queryClient.invalidateQueries(QueryKey.InternalP2PProviderWallets);
          formikHelpers.resetForm();
          onClose({ ok: true });
        },
        onError: (error: AxiosError) =>
          formikHelpers.setErrors(validationUtils.getFormErrors(error)),
        onSettled: () => {
          formikHelpers.setSubmitting(false);
        },
      };

      formikHelpers.setSubmitting(true);

      mutationCreate.mutate(values.p2pProviderId!, options);
    },
    [mutationCreate, queryClient, onClose],
  );

  const handleClose = useCallback(
    (result: CloseFormikDialogResult<Values>) => {
      if (!result.ok || !result.data) {
        result.data?.formikHelpers?.resetForm();
        onClose(result);
      } else {
        handleSubmit(result.data?.values, result.data?.formikHelpers);
      }
    },
    [handleSubmit, onClose],
  );

  const validationSchema: Yup.ObjectSchema<Values> = useMemo(
    () =>
      Yup.object().shape({
        p2pProviderId: Yup.string().required(tCommon('errors.required')),
      }),
    [tCommon],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
      isInitialValid={false}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Dialog
          open={open}
          title={t('title')}
          data={{ values: formik.values, formikHelpers: formik }}
          mutation={mutationCreate}
          disabled={formik.isSubmitting}
          okDisabled={!formik.isValid}
          onClose={handleClose}
        >
          <FormControls>
            <FormikSelect
              name="p2pProviderId"
              label={t('fields.p2p_provider')}
              required
              options={p2pProvidersOptions}
            />
          </FormControls>
        </Dialog>
      )}
    </Formik>
  );
};
