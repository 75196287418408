import i18next from 'i18next';
import * as Yup from 'yup';

import { buildFormValidator } from 'features/form-builder';
import { FormField } from 'types';

/**
 * Build common validator for requisites form
 */
const buildCommonValidator = (): Yup.ObjectSchema<any> => {
  const tCommon = i18next.t;

  const limitFieldSchema = Yup.number()
    .moreThan(0, tCommon('errors.natural_number'))
    .required(tCommon('errors.required'));

  return Yup.object().shape({
    fiatCurrencyId: Yup.string().required(tCommon('errors.required')),
    paymentTypeId: Yup.string().when('fiatCurrencyId', {
      is: (fiatCurrencyId: string) => !!fiatCurrencyId,
      then: (schema) => schema.required(tCommon('errors.required')),
      otherwise: (schema) => schema.nullable(),
    }),
    bankId: Yup.string().when('paymentTypeId', {
      is: (paymentTypeId: string) => !!paymentTypeId,
      then: (schema) => schema.required(tCommon('errors.required')),
      otherwise: (schema) => schema.nullable(),
    }),
    status: Yup.string().required(tCommon('errors.required')),
    limitActiveOrders: limitFieldSchema,
    limitCountPerHour: limitFieldSchema,
    limitCountPerDay: limitFieldSchema,
    limitCountPerMonth: limitFieldSchema,
    limitSumPerHour: limitFieldSchema,
    limitSumPerDay: limitFieldSchema,
    limitSumPerMonth: limitFieldSchema,
    completedOrdersInMinutes: Yup.number()
      .min(0, tCommon('errors.natural_or_zero_number'))
      .required(tCommon('errors.required')),
    name: Yup.string().optional().nullable(),
    groupId: Yup.string().optional().nullable(),
    accountLastDigits: Yup.string().optional().nullable(),
  });
};

/**
 * Build custom validator for requisites form
 *
 * @param fields - requisites form fields
 */
export const buildValidator = (fields?: FormField[] | null) => {
  const commonValidator = buildCommonValidator();
  if (!fields || !fields.length) {
    return commonValidator;
  }
  const validator = buildFormValidator(fields);
  return commonValidator.concat(validator);
};
