import { filter, find, map } from 'lodash';
import { useCallback, useMemo } from 'react';

import { FormikSelectOption } from 'components';
import { DEFAULT_ASSET_CURRENCY_CODE } from 'constants/order.constants';
import { CurrencyExchangeSource, FiatCurrencyExchangeSource } from 'enums';
import { Currency, CurrencyExchange } from 'types';
import { currencyExchangeUtils } from 'utils';

import { useUserContext } from './use-user-context.hook';

export const useCurrencies = () => {
  const {
    fiatCurrencies,
    assetCurrencies,
    currencyExchanges,
    assetCurrencyExchange,
  } = useUserContext();

  const getAssetCurrency = useCallback(
    (id?: string) => find(assetCurrencies, { id }),
    [assetCurrencies],
  );
  const getFiatCurrency = useCallback(
    (id?: string) => find(fiatCurrencies, { id }),
    [fiatCurrencies],
  );
  const getFiatCurrencyCode = useCallback(
    (id?: string) => getFiatCurrency(id)?.code || '',
    [getFiatCurrency],
  );
  const getFiatCurrencySymbol = useCallback(
    (id?: string) => getFiatCurrency(id)?.symbol || '',
    [getFiatCurrency],
  );
  const getAssetCurrencyCode = useCallback(
    (id?: string) => getAssetCurrency(id)?.code || '',
    [getAssetCurrency],
  );
  const getAssetCurrencySymbol = useCallback(
    (id?: string) => getAssetCurrency(id)?.symbol || '',
    [getAssetCurrency],
  );
  const getCurrencyOptions = useCallback(
    (currencies: Currency[]): FormikSelectOption[] =>
      map(currencies, (currency) => ({
        value: currency.id,
        label: currency.code,
      })),
    [],
  );
  const getDefaultAssetCurrency = useCallback(
    () => find(assetCurrencies, { code: DEFAULT_ASSET_CURRENCY_CODE }),
    [assetCurrencies],
  );
  const getCurrencyExchangeLabel = useCallback(
    (
      assetCurrencyId: string,
      fiatCurrencyId: string,
      source: CurrencyExchangeSource,
      name: string,
    ) =>
      `${getAssetCurrencyCode(assetCurrencyId)} / ${getFiatCurrencyCode(
        fiatCurrencyId,
      )} / ${currencyExchangeUtils.getCurrencyExchangeSourceLabel(source)}` +
      (name ? ` (${name})` : ''),
    [getAssetCurrencyCode, getFiatCurrencyCode],
  );

  const getFiatCurrencyExchangeLabel = useCallback(
    (
      fiatCurrencyId: string,
      crossFiatCurrencyId: string,
      source: FiatCurrencyExchangeSource,
      name?: string,
    ) =>
      `${getFiatCurrencyCode(fiatCurrencyId)} / ${getFiatCurrencyCode(
        crossFiatCurrencyId,
      )} / ${currencyExchangeUtils.getFiatCurrencyExchangeSourceLabel(
        source,
      )}` + (name ? ` (${name})` : ''),
    [getFiatCurrencyCode],
  );

  const getCurrencyExchangeOptions = useCallback(
    (currencies: {
      assetCurrencyId?: string;
      fiatCurrencyId?: string;
    }): FormikSelectOption[] =>
      map(
        filter(currencyExchanges, { ...currencies }),
        ({ id, assetCurrencyId, fiatCurrencyId, source, name }) => ({
          value: id,
          label: getCurrencyExchangeLabel(
            assetCurrencyId,
            fiatCurrencyId,
            source,
            name,
          ),
        }),
      ),
    [currencyExchanges, getCurrencyExchangeLabel],
  );
  const getCurrencyExchange = useCallback(
    (id: string) => find(currencyExchanges, { id }),
    [currencyExchanges],
  );
  const getDefaultCurrencyExchange = useCallback(
    (assetCurrencyId?: string, fiatCurrencyId?: string): CurrencyExchange => {
      const filteredCurrencyExchanges = filter(currencyExchanges, {
        fiatCurrencyId,
        assetCurrencyId,
      });
      return (
        find(filteredCurrencyExchanges, { defaultExchange: true }) ||
        filteredCurrencyExchanges[0]
      );
    },
    [currencyExchanges],
  );

  const getAssetCurrencyExchangeOptions = useCallback(
    (crossAssetCurrencyId: string) =>
      map(
        filter(
          assetCurrencyExchange,
          (exchange) => exchange.crossAssetCurrencyId === crossAssetCurrencyId,
        ),
        (item) => ({
          value: item.id,
          label: `${item.name} (${
            item.currencyExchange.price
          } ${getAssetCurrencySymbol(item.crossAssetCurrencyId)})`,
        }),
      ),
    [assetCurrencyExchange, getAssetCurrencySymbol],
  );

  const getAssetCurrencyExchange = useCallback(
    (id: string) =>
      find(assetCurrencyExchange, (exchange) => exchange.id === id),
    [assetCurrencyExchange],
  );

  const assetCurrenciesOptions = useMemo(
    () => getCurrencyOptions(assetCurrencies),
    [assetCurrencies, getCurrencyOptions],
  );

  const fiatCurrenciesOptions = useMemo(
    () => getCurrencyOptions(fiatCurrencies),
    [fiatCurrencies, getCurrencyOptions],
  );

  const defaultAssetCurrency = useMemo(
    () => getDefaultAssetCurrency(),
    [getDefaultAssetCurrency],
  );

  return {
    assetCurrencies,
    fiatCurrencies,
    currencyExchanges,
    assetCurrenciesOptions,
    fiatCurrenciesOptions,
    defaultAssetCurrency,
    getAssetCurrency,
    getAssetCurrencyCode,
    getAssetCurrencySymbol,
    getFiatCurrency,
    getFiatCurrencyCode,
    getFiatCurrencySymbol,
    getDefaultAssetCurrency,
    getCurrencyExchangeOptions,
    getDefaultCurrencyExchange,
    getFiatCurrencyExchangeLabel,
    getCurrencyExchangeLabel,
    getAssetCurrencyExchangeOptions,
    getAssetCurrencyExchange,
    getCurrencyExchange,
  };
};
