import { find } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { shopsApi, usersApi } from 'api';
import {
  AdditionalOrderColumn,
  FilterDefinitionType,
  OrdersTab,
  QueryKey,
} from 'enums';
import { Orders } from 'features';
import { useCurrencies, useUserContext } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { FilterDefinition, OrderFilters } from 'types';
import { formUtils, requisitesUtils } from 'utils';

type Props = {
  tab: OrdersTab;
  additionalFilters?: FilterDefinition<OrderFilters>[];
  additionalColumns?: AdditionalOrderColumn[];
};

export const ManageOrdersList: React.FC<Props> = ({
  tab,
  additionalFilters = [],
  additionalColumns = [],
}) => {
  const { t: tCommon } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders',
  });

  const { paymentTypes, banks, p2pProviders } = useUserContext();
  const { getFiatCurrencyCode, fiatCurrenciesOptions } = useCurrencies();

  const queryResultShops = useQuery(QueryKey.Shops, shopsApi.getAll);
  const queryResultTraders = useQuery(
    QueryKey.UsersTraders,
    usersApi.getAllTraders,
  );
  const queryResultMerchants = useQuery(
    QueryKey.UsersMerchants,
    usersApi.getAllMerchants,
  );

  const filters: FilterDefinition<OrderFilters>[] = useMemo(
    () => [
      {
        label: tCommon('filters.customer'),
        name: 'customerId',
        type: FilterDefinitionType.Text,
      },
      {
        label: tCommon('filters.trader'),
        name: 'traderId',
        type: FilterDefinitionType.User,
        users: queryResultTraders.data,
        getDisplayName: (traderId: string) =>
          find(queryResultTraders.data, { id: traderId })?.name,
      },
      {
        label: tCommon('filters.merchant'),
        name: 'merchantId',
        type: FilterDefinitionType.User,
        users: queryResultMerchants.data,
        getDisplayName: (merchantId: string) =>
          find(queryResultMerchants.data, { id: merchantId })?.name,
      },
      {
        label: tCommon('filters.shop'),
        name: 'shopId',
        type: FilterDefinitionType.Shop,
        shops: queryResultShops.data,
        getDisplayName: (shopId: string) =>
          find(queryResultShops.data, { id: shopId })?.name,
      },
      {
        label: tCommon('filters.external_order_id'),
        name: 'externalOrderId',
        type: FilterDefinitionType.Text,
      },
      {
        label: tCommon('filters.payment_type'),
        name: 'paymentTypeId',
        type: FilterDefinitionType.Select,
        options: requisitesUtils.getPaymentTypesOptions(paymentTypes),
        getDisplayName: (value: string) =>
          requisitesUtils.getPaymentTypeLabel(
            find(paymentTypes, { id: value })!,
          ),
      },
      {
        label: tCommon('filters.bank'),
        name: 'bankId',
        type: FilterDefinitionType.Select,
        options: formUtils.getOptions(banks),
        getDisplayName: (value: string) => find(banks, { id: value })?.name,
      },
      {
        label: tCommon('filters.fiat_currency'),
        name: 'fiatCurrencyId',
        type: FilterDefinitionType.Select,
        options: fiatCurrenciesOptions,
        getDisplayName: getFiatCurrencyCode,
      },
      {
        label: tCommon('filters.amount'),
        name: 'amount',
        type: FilterDefinitionType.Numeric,
      },
      {
        label: tCommon('filters.p2p_provider'),
        name: 'p2pProviderId',
        type: FilterDefinitionType.Select,
        options: formUtils.getOptions(p2pProviders),
        getDisplayName: (value: string) =>
          find(p2pProviders, { id: value })?.name,
      },
      ...requisitesUtils.getRequisitesFieldsFilters<OrderFilters>(),
    ],
    [
      banks,
      fiatCurrenciesOptions,
      p2pProviders,
      getFiatCurrencyCode,
      paymentTypes,
      queryResultMerchants.data,
      queryResultShops.data,
      queryResultTraders.data,
      tCommon,
    ],
  );

  return (
    <Orders
      tab={tab}
      additionalFilters={[...filters, ...additionalFilters]}
      additionalColumns={[
        AdditionalOrderColumn.CustomerContacts,
        AdditionalOrderColumn.Trader,
        AdditionalOrderColumn.Merchant,
        AdditionalOrderColumn.CustomerPayment,
        ...additionalColumns,
      ]}
    />
  );
};
