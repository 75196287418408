import { find } from 'lodash';
import React, { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult, useQuery } from 'react-query';

import { shopsApi, usersApi } from 'api';
import {
  CopyText,
  CopyTextId,
  DataGridColumnDefinition,
  PaginationWrapper,
  DateLabel,
  QueryFilters,
} from 'components';
import { FilterDefinitionType, QueryKey } from 'enums';
import { OrderAutomationListWithAction } from 'features/order-automation';
import { useCurrencies, useQueryFilters, useUser, useUserContext } from 'hooks';
import { TranslationNamespace } from 'i18n';
import {
  FilterDefinition,
  OrderAutomation,
  OrderAutomationFilters,
  PaginatedData,
} from 'types';
import { filtersUtils, formatUtils, requisitesUtils, formUtils } from 'utils';

type Props = {
  queryResult: UseQueryResult<PaginatedData<OrderAutomation>, unknown>;
  additionalColumns?: DataGridColumnDefinition<OrderAutomation>[];
  additionalFilters?: FilterDefinition<OrderAutomationFilters>[];
};

export const ManageOrderAutomationList: React.FC<Props> = ({
  queryResult,
  additionalColumns = [],
  additionalFilters = [],
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.order_automation',
  });

  const { paymentTypes, banks } = useUserContext();
  const { role, isManager } = useUser();
  const { getFiatCurrencyCode, fiatCurrenciesOptions } = useCurrencies();

  const queryResultShops = useQuery(
    QueryKey.Shops,
    shopsApi.getAllAsRole(role),
    {
      enabled: isManager,
    },
  );
  const queryResultTraders = useQuery(
    QueryKey.UsersTraders,
    usersApi.getAllTraders,
    { enabled: isManager },
  );

  const { filters } = useQueryFilters<OrderAutomationFilters>();

  const [selectedOrderAutomation, setSelectedOrderAutomation] =
    useState<OrderAutomation | null>(null);

  const columns = useMemo(
    (): DataGridColumnDefinition<OrderAutomation>[] => [
      {
        header: t('table.columns.id'),
        valueGetter: (item) => (
          <div>
            <CopyTextId id={item.id} />
            <DateLabel>{formatUtils.formatDate(item.createdAt)}</DateLabel>
          </div>
        ),
      },
      {
        header: t('table.columns.text'),
        valueGetter: (item) => <CopyText text={item.text} />,
      },
      {
        header: t('table.columns.order_automation_type'),
        valueGetter: (item) => {
          if (item.requisitesGroup) {
            return `${requisitesUtils.getOrderAutomationTypeLabel(
              item.orderAutomationType,
            )} (${t('group')})`;
          }

          if (item.requisites) {
            return requisitesUtils.getOrderAutomationTypeLabel(
              item.orderAutomationType,
            );
          }

          return '';
        },
      },
      ...additionalColumns,
    ],
    [t, additionalColumns],
  );

  const filtersDefinitions: FilterDefinition<OrderAutomationFilters>[] =
    useMemo(
      () => [
        {
          label: t('filters.order_id'),
          name: 'orderId',
          type: FilterDefinitionType.Text,
        },
        {
          label: t('filters.shop'),
          name: 'shopId',
          type: FilterDefinitionType.Shop,
          shops: queryResultShops.data,
          getDisplayName: (shopId: string) =>
            find(queryResultShops.data, { id: shopId })?.name,
          hidden: !isManager,
        },
        {
          label: t('filters.trader'),
          name: 'traderId',
          type: FilterDefinitionType.Trader,
          users: queryResultTraders.data,
          getDisplayName: (traderId: string) =>
            find(queryResultTraders.data, { id: traderId })?.name,
          hidden: !isManager,
        },
        {
          label: t('filters.payment_type'),
          name: 'paymentTypeId',
          type: FilterDefinitionType.Select,
          options: requisitesUtils.getPaymentTypesOptions(paymentTypes),
          getDisplayName: (value: string) =>
            requisitesUtils.getPaymentTypeLabel(
              find(paymentTypes, { id: value })!,
            ),
        },
        {
          label: t('filters.bank'),
          name: 'bankId',
          type: FilterDefinitionType.Select,
          options: formUtils.getOptions(banks),
          getDisplayName: (value: string) => find(banks, { id: value })?.name,
        },
        {
          label: t('filters.fiat_currency'),
          name: 'fiatCurrencyId',
          type: FilterDefinitionType.Select,
          options: fiatCurrenciesOptions,
          getDisplayName: getFiatCurrencyCode,
        },
        ...requisitesUtils.getRequisitesFieldsFilters<OrderAutomationFilters>(),
      ],
      [
        banks,
        fiatCurrenciesOptions,
        getFiatCurrencyCode,
        isManager,
        paymentTypes,
        queryResultShops.data,
        queryResultTraders.data,
        t,
      ],
    );

  return (
    <Fragment>
      <QueryFilters
        filtersDefinitions={[
          ...filtersUtils.getCommonFilters(filters),
          ...filtersDefinitions,
          ...additionalFilters,
        ]}
      />
      <PaginationWrapper
        queryResult={queryResult}
        ignoreState={{ refetchLoading: true }}
      >
        <OrderAutomationListWithAction
          queryResult={queryResult}
          selectedOrderAutomation={selectedOrderAutomation}
          handleOrderAutomationSelection={setSelectedOrderAutomation}
          columns={columns}
        />
      </PaginationWrapper>
    </Fragment>
  );
};
