import { Typography } from '@mui/material';
import { keys, map, transform } from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  AreaChart as RechartsAreaChart,
  Area,
  ResponsiveContainer,
  AreaProps as AreaPropsRecharts,
} from 'recharts';

import { DateFormat } from 'enums';
import { useQueryFilters } from 'hooks';
import { DateFilters } from 'types';
import { formatUtils } from 'utils';

type AreaProps = Omit<AreaPropsRecharts, 'ref'>;

type Props = {
  title?: string;
  period?: DateFilters;
  useQueryFiltersPeriod?: boolean;
  data: Record<string, any>;
  areas: AreaProps[];
  height?: string | number;
  width?: string | number;
  className?: string;
};

export const AreaChart: React.FC<Props> = ({
  title,
  data,
  period: periodProp,
  useQueryFiltersPeriod = true,
  areas,
  height = 300,
  width = '100%',
  className,
}) => {
  const { filters } = useQueryFilters<DateFilters>();

  const period = useMemo(() => {
    if (useQueryFiltersPeriod) {
      return {
        from: filters?.from,
        to: filters?.to,
      };
    }
    return periodProp;
  }, [filters, periodProp, useQueryFiltersPeriod]);

  const dates = useMemo(() => {
    const datesKeys = keys(data);

    if (datesKeys.length === 0) {
      return [];
    }

    let minDate;
    let maxDate = moment(period?.to).utc(); // from period or now

    if (period?.from) {
      minDate = moment(period.from).utc();
    }

    if (!minDate || !maxDate) {
      const dataDates = datesKeys.map((date) => moment(date));
      minDate = minDate || moment.min(dataDates);
      maxDate = maxDate || moment.max(dataDates);
    }

    const resultDates = [];
    let currentDate = moment(minDate);
    while (currentDate <= maxDate) {
      resultDates.push(
        formatUtils.formatDate(currentDate.toDate(), DateFormat.Date),
      );
      currentDate.add(1, 'day');
    }

    return resultDates;
  }, [data, period]);

  const chartData = useMemo(() => {
    const keys = map(areas, (area) => area.dataKey);
    // fill missing dates with zero values
    return dates.map((date) => ({
      date,
      ...transform(
        keys,
        (acc, key: any) => {
          acc[key] = data[date]?.[key] || 0;
        },
        {} as Record<any, number>,
      ),
    }));
  }, [areas, data, dates]);

  return (
    <div className={className}>
      {title && (
        <Typography variant="h4" className="tw-text-center tw-mb-3">
          {title}
        </Typography>
      )}
      <ResponsiveContainer width={width} height={height}>
        <RechartsAreaChart
          data={chartData}
          margin={{ top: 20, right: 30, left: 0, bottom: 10 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          {map(areas, (area, i) => (
            <Area key={i} {...area} />
          ))}
        </RechartsAreaChart>
      </ResponsiveContainer>
    </div>
  );
};
