import { Button, FormControlLabel, Link, Switch } from '@mui/material';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import AuthCode from 'react-auth-code-input';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';

import { authTwoFAApi, usersApi } from 'api';
import { CloseDialogResult, DataWrapper, Dialog } from 'components';
import { QueryKey } from 'enums';
import { useMutation } from 'hooks';
import { TranslationNamespace } from 'i18n';

const TwoFASecretInfo: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.settings.authentication',
  });
  const queryClient = useQueryClient();
  const queryResult = useQuery(QueryKey.TwoFA, authTwoFAApi.get);
  const { mutate: resetSecret, isLoading: resetSecretLoading } = useMutation(
    authTwoFAApi.reset,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.TwoFA);
        queryClient.invalidateQueries(QueryKey.Profile);
      },
    },
  );

  return (
    <DataWrapper queryResult={queryResult}>
      <div>
        <div className="tw-font-bold">{t('secret')}</div>
        <div>{queryResult.data?.secret}</div>
        <div className="tw-font-bold tw-mt-8">{t('qr')}</div>
        <div>
          <img src={queryResult.data?.qr} alt="qr" className="tw-border" />
        </div>
        {/* TODO: confirmation */}
        <div className="tw-my-4">
          <Button
            color="error"
            variant="outlined"
            onClick={() => resetSecret()}
            disabled={resetSecretLoading}
          >
            {t('buttons.reset')}
          </Button>
        </div>
      </div>
    </DataWrapper>
  );
};

export const Authentication: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.settings.authentication',
  });

  const [authenticateDialogOpen, setAuthenticateDialogOpen] = useState(false);
  const [authCode, setAuthCode] = useState<string>('');

  const queryClient = useQueryClient();
  const queryResult = useQuery(QueryKey.Profile, usersApi.getProfile);
  const twoFAEnabled = useMemo(
    () => queryResult?.data?.twoFAEnabled,
    [queryResult],
  );

  const { mutate: disable } = useMutation(authTwoFAApi.disable, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKey.Profile);
    },
  });
  const { mutate: enable } = useMutation(authTwoFAApi.enable);

  const handleTwoFAEnabledToggle = useCallback(() => {
    if (twoFAEnabled) {
      disable();
    } else {
      setAuthenticateDialogOpen(true);
    }
  }, [disable, twoFAEnabled]);

  const handleAuthenticateDialogClose = useCallback(
    ({ ok }: CloseDialogResult) => {
      if (ok) {
        enable(authCode, {
          onSuccess: () => {
            setAuthenticateDialogOpen(false);
            queryClient.invalidateQueries(QueryKey.Profile);
          },
        });
      } else {
        setAuthenticateDialogOpen(false);
      }
    },
    [authCode, enable, queryClient],
  );

  return (
    <div className="tw-my-4">
      <div className="tw-text-xl tw-mb-4">{t('title')}</div>
      <DataWrapper queryResult={queryResult}>
        <Fragment>
          <FormControlLabel
            className="tw-mb-8"
            control={
              <Switch
                checked={twoFAEnabled}
                onChange={handleTwoFAEnabledToggle}
              />
            }
            label={twoFAEnabled ? t('enabled') : t('disabled')}
          />
          <div className="tw-mb-4">
            <div className="tw-font-bold">{t('app_download')}</div>
            <span className="tw-mr-4">
              <Link
                href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('app_store')}
              </Link>
            </span>
            <Link
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('play_market')}
            </Link>
          </div>
          <TwoFASecretInfo />
        </Fragment>
      </DataWrapper>
      <Dialog
        title={t('enable_dialog.title')}
        open={authenticateDialogOpen}
        okDisabled={authCode.length !== 6}
        onClose={handleAuthenticateDialogClose}
      >
        <div className="tw-text-center">{t('enable_dialog.code_2fa')}</div>
        <AuthCode
          containerClassName="tw-px-4 tw-text-center"
          inputClassName="tw-w-10 tw-h-10 tw-m-1 tw-border tw-border-2 tw-text-center"
          allowedCharacters="numeric"
          autoFocus
          onChange={setAuthCode}
        />
      </Dialog>
    </div>
  );
};
