import { Warning as WarningIcon } from '@mui/icons-material';
import { Button, Link, Stack, Box } from '@mui/material';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CallbackUrlStatusLabel,
  CloseDialogResult,
  ConfirmButton,
  ContactInfo,
  CopyText,
  DataWrapper,
  DescriptionRow,
  Dialog,
  Money,
  OrderAgentCashback,
  OrderStatusLabel,
  PaymentTypeLabel,
  RequisitesInfo,
  StyledBankLabel,
  StylizedNumber,
} from 'components';
import { ORDER_WITH_RECEIPTS_STATUSES } from 'constants/order.constants';
import { OrderStatus, OrderType, WebhookMethod } from 'enums';
import { AutomationStatus, DisputeDialog } from 'features';
import { useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { PayinOrder, PayinOrderDispute } from 'types';
import { formatUtils, orderUtils } from 'utils';

import { OrderStatusHistory } from '../OrderStatusHistory';
import { P2PProviderOrderDetails } from '../P2PProviderOrderDetails';
import { P2PProvidersRequests } from '../P2PProvidersRequests';
import { ReceiptsModal } from '../ReceiptsModal';

type Props = {
  order: PayinOrder;
  loading: boolean;
  onConfirm: () => void;
  onCancelCompleted: () => void;
  onOpenDispute: (dispute: PayinOrderDispute) => void;
};

export const OrderDetails: React.FC<Props> = ({
  order,
  loading,
  onCancelCompleted,
  onConfirm,
  onOpenDispute,
}: Props) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders.table.columns',
  });
  const { t: tOrders } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders.details',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);

  const [receiptsModalOpen, setReceiptsModalOpen] = useState(false);
  const [disputeDialogOpen, setDisputeDialogOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const { isAdmin, isTechOperator, isMerchant, isTrader, isManager } =
    useUser();

  const canManage = useMemo(
    () => isAdmin || isTechOperator,
    [isAdmin, isTechOperator],
  );

  const isTraderOrCustomerConfirmStatus = useMemo(
    () =>
      order?.status === OrderStatus.TraderConfirm ||
      order?.status === OrderStatus.CustomerConfirm,
    [order],
  );

  const hasReceipt = useMemo(
    () => ORDER_WITH_RECEIPTS_STATUSES.includes(order?.status),
    [order],
  );

  const isCompleted = useMemo(
    () => order?.status === OrderStatus.Completed,
    [order],
  );

  const isCrossFiatPayment = useMemo(
    () => orderUtils.isCrossFiatPayment(order),
    [order],
  );

  const handleOpenDispute = useCallback(() => {
    setDisputeDialogOpen(true);
  }, []);

  const handleConfirmClick = useCallback(() => {
    setConfirmDialogOpen(true);
  }, []);

  const handleReceiptsClick = useCallback(() => {
    setReceiptsModalOpen(true);
  }, []);

  const handleReceiptsModalClose = useCallback(() => {
    setReceiptsModalOpen(false);
  }, []);

  const handleDisputeDialogClose = useCallback(
    ({ ok, data }: CloseDialogResult<PayinOrderDispute>) => {
      if (ok && data) {
        onOpenDispute?.(data);
      }
      setDisputeDialogOpen(false);
    },
    [onOpenDispute],
  );

  const handleConfirmDialogClose = useCallback(
    async ({ ok }: CloseDialogResult<PayinOrderDispute>) => {
      if (ok) {
        await onConfirm?.();
      }
      setConfirmDialogOpen(false);
    },
    [onConfirm],
  );

  return (
    <Fragment>
      <DataWrapper isLoading={loading}>
        <Stack direction="column" spacing={6}>
          <Stack direction="column" spacing={3}>
            <DescriptionRow title={t('id')} value={order.id} canCopy />
            <DescriptionRow
              title={t('status')}
              value={
                <Fragment>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <OrderStatusLabel status={order?.status} />
                    {!isMerchant && (
                      <Box ml={2}>
                        <AutomationStatus order={order} />
                      </Box>
                    )}
                  </Box>
                  {order.statusDetails && (
                    <div>
                      {orderUtils.getStatusDetailsLabel(order.statusDetails)}
                    </div>
                  )}
                </Fragment>
              }
            />
            <DescriptionRow
              title={t('amount')}
              value={
                <Fragment>
                  <Money
                    value={order.amount}
                    fiatCurrencyId={order.fiatCurrencyId}
                    symbol
                  />
                  {isCrossFiatPayment && (
                    <div>
                      {'~'}
                      <Money
                        value={order.crossFiatCurrencyAmount}
                        fiatCurrencyId={order.crossFiatCurrencyId}
                        symbol
                      />
                      {' ('}
                      <Money
                        value={order.crossFiatCurrencyRate}
                        fiatCurrencyId={order.fiatCurrencyId}
                        symbol
                      />
                      {')'}
                    </div>
                  )}
                  <div>
                    {isManager && order.warnings?.currencyRate && (
                      <WarningIcon className="tw-mr-1" color="warning" />
                    )}
                    <Money
                      value={order.assetCurrencyAmount}
                      assetCurrencyId={order.assetCurrencyId}
                      symbol
                    />
                    {' ('}
                    <Money
                      value={order.currencyRate}
                      fiatCurrencyId={order.fiatCurrencyId}
                      symbol
                    />
                    {')'}
                  </div>
                </Fragment>
              }
            />
            {(isManager || isMerchant) && isCrossFiatPayment && (
              <DescriptionRow
                title={t('spread')}
                value={
                  <StylizedNumber value={order.spreadPercentage} unit="%" />
                }
              />
            )}
            {canManage && (
              <Fragment>
                <DescriptionRow
                  title={t('customer')}
                  value={
                    <Fragment>
                      {order.customerId && <CopyText text={order.customerId} />}
                      <div>{order.customerName}</div>
                      <ContactInfo
                        phone={order.customerPhone}
                        email={order.customerEmail}
                      />
                    </Fragment>
                  }
                />
                <DescriptionRow
                  title={t('shop')}
                  value={
                    <Fragment>
                      <CopyText text={order.shopId} />
                      <div>{order.shop?.name}</div>
                    </Fragment>
                  }
                />
                <DescriptionRow
                  title={t('merchant')}
                  value={
                    <Fragment>
                      <CopyText text={order.merchantId} />
                      <div>{order.merchant?.user?.name}</div>
                    </Fragment>
                  }
                />
              </Fragment>
            )}
            {(isManager || isMerchant) && (
              <Fragment>
                {order.externalOrderId && (
                  <DescriptionRow
                    title={t('external_order_id')}
                    value={
                      <Fragment>
                        <CopyText text={order.externalOrderId} />
                      </Fragment>
                    }
                  />
                )}
                {order.callbackUrl && (
                  <DescriptionRow
                    title={t('callback')}
                    value={
                      <Fragment>
                        <div className="tw-flex">
                          <CopyText
                            className="tw-break-all"
                            text={order.callbackUrl}
                            label={`[${
                              order.callbackMethod || WebhookMethod.Post
                            }] ${order.callbackUrl}`}
                          />
                        </div>
                        {order.callbackUrlStatus && (
                          <div>
                            <CallbackUrlStatusLabel
                              status={order.callbackUrlStatus}
                            />
                          </div>
                        )}
                      </Fragment>
                    }
                  />
                )}
              </Fragment>
            )}
            {isMerchant && (
              <DescriptionRow
                title={t('accrual')}
                value={
                  <Money
                    value={order.shopAmount}
                    assetCurrencyId={order.assetCurrencyId}
                    symbol
                  />
                }
              />
            )}
            {!isMerchant && order.traderCompensation && (
              <DescriptionRow
                title={t('trader_compensation')}
                value={
                  <Money
                    value={order.traderCompensation}
                    assetCurrencyId={order.assetCurrencyId}
                    symbol
                  />
                }
              />
            )}
            {(isManager || isTrader) && isCrossFiatPayment && (
              <DescriptionRow
                title={t('trader_spread_compensation')}
                value={
                  <Money
                    value={order.traderSpreadCompensation}
                    assetCurrencyId={order.assetCurrencyId}
                    symbol
                  />
                }
              />
            )}
            {canManage && (
              <Fragment>
                {order?.shopFee && (
                  <DescriptionRow
                    title={t('shop_fee')}
                    value={
                      <Money
                        value={order.shopFee}
                        assetCurrencyId={order.assetCurrencyId}
                        symbol
                      />
                    }
                  />
                )}
                <OrderAgentCashback order={order} operation={OrderType.Payin} />
                {order.expectedProviderFeeAmount && (
                  <DescriptionRow
                    title={t('p2p_provider_fee')}
                    value={
                      <div>
                        <div
                          className="tw-flex"
                          key={t('expected_provider_fee_amount')}
                        >
                          <div className="tw-mr-2">
                            {t('expected_provider_fee_amount')}
                          </div>
                          <Money
                            value={order.expectedProviderFeeAmount || 0}
                            assetCurrencyId={order.assetCurrencyId}
                            symbol
                          />
                        </div>
                        <div
                          className="tw-flex"
                          key={t('actual_provider_fee_amount')}
                        >
                          <div className="tw-mr-2">
                            {t('actual_provider_fee_amount')}
                          </div>
                          {order.warnings?.providerFeeAmount && (
                            <WarningIcon className="tw-mr-1" color="warning" />
                          )}
                          <Money
                            value={order.actualProviderFeeAmount || 0}
                            assetCurrencyId={
                              order?.p2pProviderOrder?.assetCurrencyId ||
                              order.assetCurrencyId
                            }
                            symbol
                          />
                        </div>
                      </div>
                    }
                  />
                )}
              </Fragment>
            )}
            {isManager && order.paymentTypeId && (
              <DescriptionRow
                title={t('payment_type')}
                value={
                  <Fragment>
                    <PaymentTypeLabel paymentTypeId={order.paymentTypeId} />
                    {order.bankId && <StyledBankLabel bankId={order.bankId} />}
                  </Fragment>
                }
              />
            )}
            {order.traderId && order.requisites && (
              <DescriptionRow
                title={t('trader_requisites')}
                value={
                  <RequisitesInfo
                    cardholder={order.requisites.cardholder}
                    bankId={order.requisites.bankId || order.bankId}
                    paymentTypeId={
                      order.requisites.paymentTypeId || order.paymentTypeId
                    }
                    cardInfo={order.requisites.cardInfo}
                    phone={order.requisites.phone}
                    swiftBic={order.requisites.swiftBic}
                    bic={order.requisites.bic}
                    email={order.requisites.email}
                    idCard={order.requisites.idCard}
                    beneficiaryName={order.requisites.beneficiaryName}
                    accountNumber={order.requisites.accountNumber}
                    name={order.requisites.name}
                    expirationDate={order.requisites.expirationDate}
                  />
                }
              />
            )}
            {(order.paymentCustomerCardFirstDigits ||
              order.paymentCustomerCardLastDigits) && (
              <DescriptionRow
                title={t('customer_requisites')}
                value={
                  <div>
                    {formatUtils.formatCardDigits({
                      firstDigits: order.paymentCustomerCardFirstDigits,
                      lastDigits: order.paymentCustomerCardLastDigits,
                    })}
                  </div>
                }
              />
            )}
            {order.paymentCustomerPhoneLastDigits && (
              <DescriptionRow
                title={t('customer_phone')}
                value={formatUtils.formatPhoneLastDigits(
                  order.paymentCustomerPhoneLastDigits,
                )}
              />
            )}
            {order.paymentCustomerBank && (
              <DescriptionRow
                title={t('customer_bank')}
                value={order.paymentCustomerBank}
              />
            )}
            {order.paymentCustomerName && (
              <DescriptionRow
                title={t('customer_name')}
                value={order.paymentCustomerName}
              />
            )}
            {order.paymentCustomerIBAN && (
              <DescriptionRow
                title={t('customer_iban')}
                value={order.paymentCustomerIBAN}
              />
            )}
            {order.paymentCustomerAccountNumber && (
              <DescriptionRow
                title={t('customer_account_number')}
                value={order.paymentCustomerAccountNumber}
              />
            )}
            {order.paymentCustomerUtr && (
              <DescriptionRow
                title={t('utr')}
                value={order.paymentCustomerUtr}
              />
            )}
            {orderUtils.isPaid(order) && (
              <DescriptionRow
                title={t('confirm_date')}
                value={formatUtils.formatDate(
                  orderUtils.getStatusDate(order, OrderStatus.Completed),
                )}
              />
            )}
            {hasReceipt && (
              <DescriptionRow
                title={t('receipts')}
                value={
                  <div>
                    <Link
                      component="button"
                      variant="body2"
                      underline="none"
                      onClick={handleReceiptsClick}
                    >
                      {tCommon('buttons.open')}
                    </Link>
                  </div>
                }
              />
            )}
            {isManager && <P2PProvidersRequests order={order} />}
            {order.p2pProviderOrder && (
              <P2PProviderOrderDetails order={order} />
            )}
            {isManager && (
              <OrderStatusHistory order={order} loading={loading} />
            )}
          </Stack>
          {isTrader &&
            isTraderOrCustomerConfirmStatus &&
            !orderUtils.isStatusTimeoutOver(order) && (
              <Stack direction="column" spacing={3}>
                <Button
                  variant="outlined"
                  color="error"
                  disabled={loading}
                  onClick={handleOpenDispute}
                  fullWidth
                >
                  {tOrders('open_dispute')}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={loading}
                  onClick={handleConfirmClick}
                  fullWidth
                >
                  {tCommon('buttons.confirm')}
                </Button>
              </Stack>
            )}
          {isManager && isTraderOrCustomerConfirmStatus && (
            <Button
              variant="outlined"
              color="error"
              disabled={loading}
              onClick={handleOpenDispute}
              fullWidth
            >
              {tOrders('open_dispute')}
            </Button>
          )}
          {isManager && isCompleted && (
            <Stack direction="column" spacing={3}>
              <ConfirmButton
                variant="outlined"
                color="error"
                disabled={loading}
                fullWidth
                onConfirm={onCancelCompleted}
              >
                {tCommon('buttons.cancel')}
              </ConfirmButton>
            </Stack>
          )}
        </Stack>
      </DataWrapper>
      {hasReceipt && (
        <ReceiptsModal
          open={receiptsModalOpen}
          order={order}
          onClose={handleReceiptsModalClose}
        />
      )}
      <DisputeDialog
        open={disputeDialogOpen}
        order={order}
        title={tOrders('open_dispute')}
        onClose={handleDisputeDialogClose}
      />
      <Dialog
        title={tOrders('confirm_dialog.title')}
        onClose={handleConfirmDialogClose}
        open={confirmDialogOpen}
      >
        {tOrders('confirm_dialog.description')}
      </Dialog>
    </Fragment>
  );
};
