import { Button, FormControlLabel, Grid, Paper } from '@mui/material';
import { AxiosError } from 'axios';
import {
  Field,
  FieldArray,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
} from 'formik';
import { Checkbox } from 'formik-mui';
import { includes, map, orderBy, sortBy } from 'lodash';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { groupsApi, operatorsApi, shopsApi, tradersApi } from 'api';
import {
  CopyText,
  DataWrapper,
  FormControls,
  FormikCheckbox,
  FormikNumericTextField,
  FormikTextField,
  PageHeader,
} from 'components';
import { NEW_ID } from 'constants/common.constants';
import { ROUTE_PATH } from 'constants/routes';
import { QueryKey } from 'enums';
import { useMutation, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { Group, Operator, Shop, Trader } from 'types';
import { validationUtils } from 'utils';

type Values = Pick<Group, 'name' | 'payin' | 'payout' | 'telegramChatId'> & {
  traders: string[];
  shops: string[];
  operators: string[];
};

export const GroupDetailsPage: React.FC = () => {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const { role, isAdmin, isTechOperator } = useUser();

  // TODO: move translations to common
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.group_details',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);

  const [initialValues, setInitialValues] = useState<Values>({
    name: '',
    traders: [],
    shops: [],
    operators: [],
    payin: false,
    payout: false,
    telegramChatId: null,
  });

  const [traders, setTraders] = useState<Trader[]>([]);
  const [operators, setOperators] = useState<Operator[]>([]);
  const [shops, setShops] = useState<Shop[]>([]);

  const isNew = useMemo(() => id === NEW_ID, [id]);
  const title = useMemo(
    () => (isNew ? t('title_create') : t('title_edit')),
    [t, isNew],
  );

  const backUrl = useMemo(() => {
    if (isAdmin) {
      return ROUTE_PATH.ADMIN.GROUPS;
    } else if (isTechOperator) {
      return ROUTE_PATH.TECH_OPERATOR.GROUPS;
    }
  }, [isAdmin, isTechOperator]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required(tCommon('errors.required')),
      }),
    [tCommon],
  );

  // to have selected on top on save
  const sortTraders = useCallback(
    (values = initialValues) => {
      setTraders((prev) =>
        sortBy(prev, (trader) => !includes(values.traders, trader.id)),
      );
    },
    [initialValues],
  );

  // to have selected on top on save
  const sortShops = useCallback(
    (values = initialValues) => {
      setShops((prev) =>
        sortBy(prev, (shop) => !includes(values.shops, shop.id)),
      );
    },
    [initialValues],
  );

  // to have selected on top on save
  const sortOperators = useCallback(
    (values = initialValues) => {
      setOperators((prev) =>
        sortBy(prev, (operator) => !includes(values.operators, operator.id)),
      );
    },
    [initialValues],
  );

  const setInitialValuesFromGroup = useCallback(
    (group: Group) => {
      const newValue = {
        name: group?.name,
        traders: map(group.traders, (trader) => trader.id || ''),
        shops: map(group.shops, (shop) => shop.id || ''),
        operators: map(group.operators, (operator) => operator.id || ''),
        payin: group.payin,
        payout: group.payout,
        telegramChatId: group.telegramChatId,
      };
      setInitialValues(newValue);
      sortTraders(newValue);
      sortShops(newValue);
      sortOperators(newValue);
    },
    [sortTraders, sortShops, sortOperators],
  );

  const queryResultGroup = useQuery(
    [QueryKey.Groups, id],
    () => groupsApi.getOne(id),
    {
      enabled: !isNew,
      onSuccess: setInitialValuesFromGroup,
    },
  );

  const queryResultTraders = useQuery(
    QueryKey.Traders,
    () => tradersApi.getAllAsRole(role)(),
    {
      onSuccess: setTraders,
    },
  );

  const queryResultShops = useQuery(QueryKey.Shops, shopsApi.getAll, {
    onSuccess: setShops,
  });

  const queryResultOperators = useQuery(
    QueryKey.Operators,
    () => operatorsApi.getAll(),
    {
      onSuccess: setOperators,
    },
  );

  const queryResults = useMemo(() => {
    const queries = [
      queryResultGroup,
      queryResultTraders,
      queryResultShops,
      queryResultOperators,
    ];
    return queries;
  }, [
    queryResultGroup,
    queryResultShops,
    queryResultTraders,
    queryResultOperators,
  ]);

  const { mutate: createGroup } = useMutation<
    Group,
    AxiosError,
    Partial<Group>,
    unknown
  >(groupsApi.create);

  const { mutate: updateGroup } = useMutation<
    Group,
    AxiosError,
    { id: string; data: Partial<Group> },
    unknown
  >(groupsApi.update);

  const handleSubmit = useCallback(
    (values: Values, formikHelpers: FormikHelpers<Values>) => {
      const options = {
        onSuccess: () => {
          navigate(backUrl!);
        },
        onError: (error: AxiosError) => {
          formikHelpers.setErrors(validationUtils.getFormErrors(error));
        },
        onSettled: () => formikHelpers.setSubmitting(false),
      };

      const mappedValues = {
        ...values,
        telegramChatId: values.telegramChatId || null,
        traders: map(values.traders, (traderId) => ({ id: traderId })),
        shops: map(values.shops, (shopId) => ({ id: shopId })),
        operators: map(values.operators, (operatorId) => ({ id: operatorId })),
      };

      if (isNew) {
        createGroup(mappedValues, options);
      } else {
        updateGroup({ id, data: mappedValues }, options);
      }
    },
    [id, isNew, backUrl, navigate, createGroup, updateGroup],
  );

  const renderSelectionList = useCallback(
    ({
      formik,
      name,
      title,
      items,
      labelGetter,
    }: {
      formik: FormikProps<Values>;
      name: 'traders' | 'shops' | 'operators';
      title: string;
      items: { id: string }[] | undefined;
      labelGetter: (item: any) => string | undefined;
    }) => (
      <Grid item xs={12} md={4} sx={{ display: 'flex' }}>
        <div className="tw-flex-1 tw-flex tw-flex-col">
          <div className="tw-text-xl tw-mb-4">
            {`${title} (${formik.values[name].length})`}
          </div>
          <Paper
            elevation={0}
            sx={{ padding: 3, paddingX: 3 }}
            className="md:tw-flex-1"
          >
            {!items?.length ? (
              <div>{t('no_selection')}</div>
            ) : (
              <div className="tw-max-h-40 tw-overflow-auto">
                <FieldArray name={name}>
                  {() =>
                    map(
                      orderBy(items, [
                        (item) => !initialValues[name].includes(item.id),
                        (item) => labelGetter(item),
                      ]),
                      (item) => (
                        <FormControlLabel
                          key={item.id}
                          sx={{ display: 'flex' }}
                          control={
                            <Field
                              component={Checkbox}
                              type="checkbox"
                              name={name}
                              value={item.id}
                              sx={{ paddingTop: 1, paddingBottom: 1 }}
                            />
                          }
                          label={labelGetter(item)}
                        />
                      ),
                    )
                  }
                </FieldArray>
              </div>
            )}
          </Paper>
        </div>
      </Grid>
    ),
    [t, initialValues],
  );

  return (
    <Fragment>
      <PageHeader title={title}></PageHeader>
      <DataWrapper queryResult={queryResults} ignoreState={{ empty: isNew }}>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form>
              <div className="tw-mb-6">
                <div className="tw-text-xl tw-mb-4">{t('general_info')}</div>
                <FormControls>
                  {!isNew && (
                    <CopyText
                      className="tw-mb-4"
                      text={queryResultGroup.data?.id || ''}
                    />
                  )}
                  <FormikTextField
                    label={t('fields.name')}
                    name="name"
                    type="text"
                    fullWidth
                    required
                    sx={{ maxWidth: 300 }}
                  />
                  <FormikNumericTextField
                    label={t('fields.telegram_chat_id')}
                    name="telegramChatId"
                    fullWidth
                    sx={{ maxWidth: 300 }}
                    decimalScale={0}
                    thousandSeparator=""
                  />
                  <div>
                    <FormikCheckbox name="payin" label={t('fields.payin')} />
                    <FormikCheckbox name="payout" label={t('fields.payout')} />
                  </div>
                </FormControls>
              </div>
              <Grid container spacing={6}>
                {renderSelectionList({
                  formik,
                  title: t('fields.traders'),
                  name: 'traders',
                  items: traders,
                  labelGetter: (trader: Trader) => trader?.user?.name,
                })}
                {renderSelectionList({
                  formik,
                  title: t('fields.shops'),
                  name: 'shops',
                  items: shops,
                  labelGetter: (shop: Shop) => shop.name,
                })}
                {renderSelectionList({
                  formik,
                  title: t('fields.operators'),
                  name: 'operators',
                  items: operators,
                  labelGetter: (operator: Operator) => operator?.user?.name,
                })}
              </Grid>
              <div className="tw-mt-8">
                <Button
                  type="submit"
                  variant="outlined"
                  disabled={formik.isSubmitting}
                  onClick={formik.submitForm}
                >
                  {tCommon('buttons.save')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </DataWrapper>
    </Fragment>
  );
};
