import axios from 'axios';

import { env } from 'config';
import { Wallet } from 'types';

const url = `${env.apiUrl}/wallets`;

const getAll = async (): Promise<Wallet[]> => (await axios.get(`${url}`)).data;

const create = async (shop: Partial<Wallet>): Promise<Wallet> =>
  (await axios.post(url, shop)).data;

const findOne = async (id: string): Promise<Wallet> =>
  (await axios.get(`${url}/${id}`)).data;

const update = async ({
  id,
  wallet,
}: {
  id: string;
  wallet: Partial<Wallet>;
}): Promise<Wallet> => (await axios.patch(`${url}/${id}`, wallet)).data;

const remove = async (wallet: Wallet): Promise<Wallet> =>
  (await axios.delete(`${url}/${wallet.id}`)).data;

export const walletsApi = {
  getAll,
  findOne,
  create,
  update,
  remove,
};
