import { Button, Stack } from '@mui/material';
import { AxiosError } from 'axios';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseMutateFunction } from 'react-query';
import * as Yup from 'yup';

import { PlatformWalletSelect } from 'components';
import { TranslationNamespace } from 'i18n';
import { DeprecatedUserWallet } from 'types';
import { validationUtils } from 'utils';

type Values = DeprecatedUserWallet;

type Props = {
  initialValues: Values;
  onSubmit: UseMutateFunction<DeprecatedUserWallet, AxiosError, any, unknown>;
};

export const WalletForm: React.FC<Props> = ({
  initialValues: initialValuesProp,
  onSubmit,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.settings.wallet',
  });

  const [initialValues, setInitialValues] = useState<Values>(
    initialValuesProp || { platformWalletId: '' },
  );

  const { t: tCommon } = useTranslation(TranslationNamespace.Common);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        platformWalletId: Yup.string().required(tCommon('errors.required')),
      }),
    [tCommon],
  );

  const handleSubmit = useCallback(
    (values: Values, { setSubmitting, setErrors }: FormikHelpers<Values>) => {
      onSubmit(values, {
        onSuccess: (user) => {
          setInitialValues({ platformWalletId: user.platformWalletId });
        },
        onSettled: () => {
          setSubmitting(false);
        },
        onError: (error: AxiosError) => {
          setErrors(validationUtils.getFormErrors(error));
        },
      });
    },
    [onSubmit],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form>
          <Stack sx={{ maxWidth: 320 }}>
            <PlatformWalletSelect
              label={t('fields.platform_wallet')}
              name="platformWalletId"
              variant="standard"
            />
            <div className="tw-mt-4">
              <Button
                type="submit"
                variant="outlined"
                disabled={formik.isSubmitting}
              >
                {tCommon('buttons.save')}
              </Button>
            </div>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
