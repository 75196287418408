import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import { Button, Divider, Stack, TextField, Typography } from '@mui/material';
import { map } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { payoutOrdersFilesValidationApi } from 'api';
import { CloseDialogResult, Dialog, DialogProps } from 'components';
import { QueryKey } from 'enums';
import { useMutation, usePrevious } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { TradeMethodPayoutPDFTemplate } from 'types';

type Props = DialogProps<TradeMethodPayoutPDFTemplate>;

export const PayoutPDFtemplateDetailsDialog: React.FC<Props> = ({
  open,
  data,
  onClose,
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix:
      'pages.trade_methods.files_validation.payout_pdf_templates.details',
  });

  const [template, setTemplate] = useState(data?.template || '');
  const prevOpen = usePrevious(open);

  const isNew = useMemo(() => !data?.id, [data]);

  const title = useMemo(
    () => t(isNew ? 'create_title' : 'edit_title'),
    [isNew, t],
  );

  const {
    mutate: parsePdfTemplateText,
    data: parsePdfTemplateData,
    isLoading: isParseLoading,
    reset: resetParsePdfTemplateData,
  } = useMutation(
    payoutOrdersFilesValidationApi.pdfTemplates.parsePdfTemplateText,
    { notifierType: 'execute' },
  );

  const { mutate: create, isLoading: isCreateLoading } = useMutation(
    payoutOrdersFilesValidationApi.pdfTemplates.createForTradeMethod,
  );

  const { mutate: update, isLoading: isUpdateLoading } = useMutation(
    payoutOrdersFilesValidationApi.pdfTemplates.update,
  );

  const isLoading = useMemo(
    () => isParseLoading || isCreateLoading || isUpdateLoading,
    [isParseLoading, isCreateLoading, isUpdateLoading],
  );

  const parsedData = useMemo(() => {
    if (!parsePdfTemplateData) {
      return '';
    }

    return map(
      parsePdfTemplateData.parseResult,
      (value, key) => `${key}: ${value}`,
    ).join('\n');
  }, [parsePdfTemplateData]);

  const handleTemplateChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const value = e.target.value;
      setTemplate(value);
    },
    [],
  );

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();
      const file = event.target.files?.[0];
      event.target.value = '';
      if (!file) {
        return;
      }

      const formData = new FormData();
      formData.append('file', file);

      parsePdfTemplateText({ template: data, data: formData });
    },
    [data, parsePdfTemplateText],
  );

  const handleClose = useCallback(
    (result: CloseDialogResult) => {
      if (!result.ok) {
        return onClose(result);
      }
      const options = {
        onSuccess: () => {
          queryClient.invalidateQueries([
            QueryKey.TradeMethodPayoutPDFTemplates,
            data!.tradeMethodId,
          ]);
          onClose(result);
        },
      };
      if (isNew) {
        create({ id: data!.tradeMethodId, data: { template } }, options);
      } else {
        update({ id: data!.id, data: { template } }, options);
      }
    },
    [create, data, isNew, onClose, queryClient, template, update],
  );

  useEffect(() => {
    if (open && !prevOpen) {
      setTemplate(data?.template || '');
    }
    if (!open && prevOpen) {
      resetParsePdfTemplateData();
    }
  }, [data?.template, open, prevOpen, resetParsePdfTemplateData]);

  return (
    <Dialog
      open={open}
      title={title}
      maxWidth="sm"
      okDisabled={!template}
      disabled={isLoading}
      onClose={handleClose}
    >
      <TextField
        variant="outlined"
        type="text"
        multiline
        minRows={3}
        fullWidth
        value={template}
        onChange={handleTemplateChange}
      />
      <Divider sx={{ my: 5 }} />
      <Typography variant="h6" sx={{ mb: 2 }}>
        {t('parse_file')}
      </Typography>
      <Stack spacing={4}>
        <div>
          <Button
            component="label"
            variant="outlined"
            startIcon={<CloudUploadIcon />}
            disabled={isLoading}
          >
            {t('upload_pdf')}
            <TextField
              type="file"
              sx={{ display: 'none' }}
              inputProps={{
                accept: 'application/pdf',
              }}
              onChange={handleFileChange}
            />
          </Button>
          <Typography
            variant="caption"
            component="div"
            sx={{ fontStyle: 'italic' }}
          >
            {`*${t('parse_description')}`}
          </Typography>
        </div>
        {parsePdfTemplateData?.text && (
          <TextField
            type="text"
            multiline
            fullWidth
            value={parsePdfTemplateData?.text}
          />
        )}
        {parsedData && (
          <TextField
            type="text"
            multiline
            fullWidth
            disabled
            value={parsedData}
          />
        )}
      </Stack>
    </Dialog>
  );
};
