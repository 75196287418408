import { Tab } from '@mui/material';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { PageHeader, QueryTabPanel, QueryTabs } from 'components';
import { P2PProvidersPage } from 'features/p2p-providers';
import {
  RequisitesList,
  RequisitesTelegramAutomation,
} from 'features/requisites';
import { useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';

import { WalletsTab } from './Wallets';

enum ArchiveTabs {
  Requisites = 'requisites',
  Wallets = 'wallets',
  RequisitesTelegramAutomation = 'telegram-automation',
  P2PProviders = 'p2p-providers',
}

export const ArchivePage = () => {
  // TODO: move tkeys
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.archive',
  });
  const { isAdmin, isTechOperator } = useUser();

  return (
    <Fragment>
      <PageHeader title={t('title')} divider={false} />
      <QueryTabs tabsEnum={ArchiveTabs}>
        <Tab
          value={ArchiveTabs.Requisites}
          label={t(`tabs.${ArchiveTabs.Requisites}`)}
        />
        {(isAdmin || isTechOperator) && (
          <Tab
            value={ArchiveTabs.RequisitesTelegramAutomation}
            label={t(`tabs.requisites_telegram_automation`)}
          />
        )}
        {isAdmin && (
          <Tab
            value={ArchiveTabs.Wallets}
            label={t(`tabs.${ArchiveTabs.Wallets}`)}
          />
        )}
        {(isAdmin || isTechOperator) && (
          <Tab
            value={ArchiveTabs.P2PProviders}
            label={t(`tabs.p2p_providers`)}
          />
        )}
      </QueryTabs>
      <QueryTabPanel value={ArchiveTabs.Requisites}>
        <RequisitesList archived />
      </QueryTabPanel>
      {(isAdmin || isTechOperator) && (
        <QueryTabPanel value={ArchiveTabs.RequisitesTelegramAutomation}>
          <RequisitesTelegramAutomation archived />
        </QueryTabPanel>
      )}
      {isAdmin && (
        <QueryTabPanel value={ArchiveTabs.Wallets}>
          <WalletsTab />
        </QueryTabPanel>
      )}
      {(isAdmin || isTechOperator) && (
        <QueryTabPanel value={ArchiveTabs.P2PProviders}>
          <P2PProvidersPage archived />
        </QueryTabPanel>
      )}
    </Fragment>
  );
};
