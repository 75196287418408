import i18next from 'i18next';

import { CopyTextId, DataGridColumnDefinition } from 'components';
import { UnitPosition } from 'enums';
import { Currency } from 'types';

const getCurrencySymbolPositionLabel = (position: UnitPosition) => {
  const { t } = i18next;
  const key = `features.currencies.symbol_position.${position}`;
  return i18next.exists(key) ? t(key as any) : key;
};

const getCurrencyColumns = (): DataGridColumnDefinition<Currency>[] => {
  const { t } = i18next;
  const prefix = 'features.currencies.fields';
  return [
    {
      header: t(`${prefix}.id`),
      valueGetter: (item) => <CopyTextId id={item.id} />,
    },
    {
      header: t(`${prefix}.code`),
      valueKey: 'code',
    },
    {
      header: t(`${prefix}.symbol`),
      valueKey: 'symbol',
    },
  ];
};

export const currenciesUtils = {
  getCurrencyColumns,
  getCurrencySymbolPositionLabel,
};
