import { AxiosError } from 'axios';
import { Formik, FormikHelpers } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';

import { internalWalletsApi } from 'api';
import {
  CloseFormikDialogResult,
  DataWrapper,
  Dialog,
  DialogProps,
  FormControls,
  FormikCheckbox,
  FormikNumericField,
} from 'components';
import { QueryKey } from 'enums';
import { useCurrencies, useMutation } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { InternalPlatformWalletConfigDto, InternalWallet } from 'types';
import { validationUtils } from 'utils';

type Values = InternalPlatformWalletConfigDto;

type Props = DialogProps<InternalWallet>;

export const PlatformWalletDetailsDialog: React.FC<Props> = ({
  open,
  data,
  onClose,
  ...rest
}) => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.internal_wallets.platform_wallet_dialog',
  });

  const queryClient = useQueryClient();
  const { getDefaultAssetCurrency } = useCurrencies();

  const [initialValues, setInitialValues] = useState<Values>({
    desirableMaxBalanceLimit: 0,
    deposit: true,
    withdrawal: true,
    accountActivation: false,
  });

  const isNew = useMemo(() => !data?.id, [data]);

  const queryResult = useQuery(
    [QueryKey.InternalPlatformWalletConfig, data?.id],
    () => internalWalletsApi.getOnePlatform(data?.id!),
    {
      enabled: open && !isNew,
      onSuccess: (data) => {
        setInitialValues({
          desirableMaxBalanceLimit:
            data.platformWalletConfig?.desirableMaxBalanceLimit ?? 0,
          deposit: data.platformWalletConfig?.deposit ?? false,
          withdrawal: data.platformWalletConfig?.withdrawal ?? false,
          accountActivation:
            data.platformWalletConfig?.accountActivation ?? false,
        });
      },
    },
  );

  const mutationCreate = useMutation<InternalWallet, AxiosError, Values>(
    internalWalletsApi.createPlatform,
  );

  const mutationUpdate = useMutation<
    InternalWallet,
    AxiosError,
    { id: string; data: Values }
  >(internalWalletsApi.updatePlatform);

  const title = useMemo(
    () => (isNew ? t('title.create') : t('title.edit')),
    [t, isNew],
  );

  const handleSubmit = useCallback(
    (values: Values, formikHelpers: FormikHelpers<Values>) => {
      const options = {
        onSuccess: () => {
          queryClient.invalidateQueries(QueryKey.InternalPlatformWallets);
          onClose({ ok: true });
        },
        onError: (error: AxiosError) =>
          formikHelpers.setErrors(validationUtils.getFormErrors(error)),
        onSettled: () => {
          formikHelpers.setSubmitting(false);
        },
      };

      formikHelpers.setSubmitting(true);

      if (isNew) {
        mutationCreate.mutate(values, options);
      } else {
        mutationUpdate.mutate({ id: data?.id!, data: values }, options);
      }
    },
    [isNew, queryClient, onClose, mutationCreate, mutationUpdate, data],
  );

  const handleClose = useCallback(
    (result: CloseFormikDialogResult<Values>) => {
      if (!result.ok || !result.data) {
        result.data?.formikHelpers?.resetForm();
        onClose(result);
      } else {
        handleSubmit(result.data?.values, result.data?.formikHelpers);
      }
    },
    [handleSubmit, onClose],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(formik) => (
        <Dialog
          open={open}
          title={title}
          data={{ values: formik.values, formikHelpers: formik }}
          mutation={isNew ? mutationCreate : mutationUpdate}
          disabled={formik.isSubmitting}
          onClose={handleClose}
        >
          <DataWrapper queryResult={isNew ? undefined : queryResult}>
            <FormControls>
              <FormControls row>
                <FormikCheckbox name="deposit" label={t('fields.deposit')} />
                <FormikCheckbox
                  name="withdrawal"
                  label={t('fields.withdrawal')}
                />
              </FormControls>
              <FormControls row>
                <FormikCheckbox
                  name="accountActivation"
                  label={t('fields.account_activation')}
                />
              </FormControls>
              <FormikNumericField
                name="desirableMaxBalanceLimit"
                label={t('fields.desirable_max_balance_limit')}
                helperText={t('fields.desirable_max_balance_limit_helper')}
                allowNegative={false}
                suffix={getDefaultAssetCurrency()?.symbol}
              />
            </FormControls>
          </DataWrapper>
        </Dialog>
      )}
    </Formik>
  );
};
