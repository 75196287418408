import { filter, isEmpty } from 'lodash';
import React, { useMemo } from 'react';

import { FiltersChips, FiltersDropdown } from 'components';
import { useQueryFilters } from 'hooks';
import { DateFilters, FilterDefinition } from 'types';
import { filtersUtils } from 'utils';

type Props = {
  filtersDefinitions: FilterDefinition[];
  withCommon?: boolean;
};

export type QueryFiltersProps = Props;

export const QueryFilters: React.FC<Props> = ({
  filtersDefinitions: filtersDefinitionsProp,
  withCommon,
}) => {
  const { filters, setFilters, resetFilter, resetFilters } =
    useQueryFilters<DateFilters>();

  const filtersDefinitions = useMemo(() => {
    const definitions = [];
    if (withCommon) {
      definitions.push(...filtersUtils.getCommonFilters(filters));
    }
    return [...definitions, ...(filtersDefinitionsProp || [])];
  }, [filters, filtersDefinitionsProp, withCommon]);

  if (isEmpty(filter(filtersDefinitions, (f) => !f.hidden))) {
    return null;
  }

  return (
    <div className="tw-flex tw-text-right tw-my-2">
      <div className="tw-flex-1 tw-text-left">
        <FiltersChips
          filters={filters}
          definitions={filtersDefinitions}
          onDelete={(name: string) => resetFilter(name)}
        />
      </div>
      <FiltersDropdown
        initialValues={filters}
        filters={filters}
        definitions={filtersDefinitions}
        onReset={resetFilters}
        onSubmit={setFilters}
      />
    </div>
  );
};
