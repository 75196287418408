import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AdditionalOrderColumn,
  CallbackUrlStatus,
  CancelStatusDetails,
  FilterDefinitionType,
  OrdersTab,
} from 'enums';
import { TranslationNamespace } from 'i18n';
import { FilterDefinition, OrderFilters } from 'types';
import { orderUtils } from 'utils';

import { ManageOrdersList } from '../ManageOrderList';

export const CancelledOrders: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders',
  });

  const additionalFilters: FilterDefinition<OrderFilters>[] = useMemo(
    () => [
      {
        label: t('filters.callback_url_status'),
        name: 'callbackUrlStatus',
        type: FilterDefinitionType.Enum,
        enum: CallbackUrlStatus,
        getDisplayName: orderUtils.getCallbackUrlStatusLabel,
      },
      {
        label: t('filters.cancel_reason'),
        name: 'statusDetails',
        type: FilterDefinitionType.Enum,
        enum: Object.values(CancelStatusDetails),
        getDisplayName: (value: CancelStatusDetails) =>
          orderUtils.getStatusDetailsLabel(value),
      },
    ],
    [t],
  );

  return (
    <ManageOrdersList
      tab={OrdersTab.Cancelled}
      additionalColumns={[
        AdditionalOrderColumn.CancelReason,
        AdditionalOrderColumn.CancelledToDispute,
      ]}
      additionalFilters={additionalFilters}
    />
  );
};
