import axios from 'axios';

import { env } from 'config';
import { UserStatus } from 'enums';
import {
  Referral,
  User,
  UserProfile,
  UserAgentInfo,
  DeprecatedUserWallet,
  QueryParams,
} from 'types';

const url = `${env.apiUrl}/users`;

const getProfile = async (): Promise<User> =>
  (await axios.get(`${url}/me`)).data;

const updateProfile = async (user: Partial<User>): Promise<User> =>
  (
    await axios.patch(`${url}/me`, user, {
      withCredentials: !!user.password,
    })
  ).data;

const getAll = async (): Promise<User[]> => (await axios.get(url)).data;

const getAllFiltered = async (params: QueryParams): Promise<User[]> =>
  (await axios.get(url, { params })).data;

const getAllWithPlatform = async (): Promise<User[]> =>
  (await axios.get(`${url}/with-platform`)).data;

const getOne = async (id: string): Promise<User> =>
  (await axios.get(`${url}/${id}`)).data;

const updateOneAdmin = async ({
  id,
  data,
}: {
  id: string;
  data: UserProfile | DeprecatedUserWallet;
}): Promise<User> => (await axios.patch(`${url}/${id}`, data)).data;

const updateStatusAdmin = async ({
  id,
  data,
}: {
  id: string;
  data: { status: UserStatus };
}): Promise<User> => (await axios.patch(`${url}/${id}/status`, data)).data;

const getAllTraders = async (): Promise<User[]> =>
  (await axios.get(`${url}/traders`)).data;

const getAllMerchants = async (): Promise<User[]> =>
  (await axios.get(`${url}/merchants`)).data;

const remove = async (user: User): Promise<User[]> =>
  (await axios.delete(`${url}/${user.id}`)).data;

const getReferralTraders = async (): Promise<Referral[]> =>
  (await axios.get(`${url}/referral/traders`)).data;

const resetPassword = async (id: string): Promise<{ password: string }> =>
  (await axios.post(`${url}/${id}/reset-password`)).data;

const disableTwoFA = async (id: string): Promise<User> =>
  (await axios.post(`${url}/${id}/disable-2fa`)).data;

const updateAgents = async ({
  id,
  data,
}: {
  id: string;
  data: UserAgentInfo;
}): Promise<User> => (await axios.post(`${url}/${id}/agents`, data)).data;

const cancelTelegramNotifications = async () =>
  (await axios.post(`${url}/me/cancel-telegram-notifications`)).data;

const getAssetHolders = async (): Promise<User[]> =>
  (await axios.get(`${url}/asset-holders`)).data;

export const usersApi = {
  getProfile,
  updateProfile,
  getAll,
  getAllWithPlatform,
  getAllFiltered,
  getOne,
  getAllTraders,
  getAllMerchants,
  remove,
  getReferralTraders,
  updateOneAdmin,
  updateStatusAdmin,
  disableTwoFA,
  updateAgents,
  resetPassword,
  cancelTelegramNotifications,
  getAssetHolders,
};
