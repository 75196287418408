export enum PayoutDisputeStatusDetails {
  DisputePaymentTimeout = 'payment_timeout',
  DisputeInvalidRequisites = 'invalid_requisites',
  DisputePaymentFailed = 'payment_failed',
  DisputeRevertCancelled = 'revert_cancelled',
  DisputeVerify = 'dispute_verify',
}

export enum PayoutRejectStatusDetails {
  RejectNoFunds = 'no_funds',
  RejectRequisitesBlocked = 'requisites_blocked',
  RejectPaymentImpossible = 'payment_impossible',
}

export enum PayoutCancelStatusDetails {
  CancelledByShop = 'shop',
  CancelledByAdmin = 'admin',
  CancelledRequisitesTimeout = 'requisites_timeout',
  CancelledMaxTimeout = 'max_timeout',
  CancelledMaxRejectsExceeded = 'max_rejects_exceeded',
  CancelledByOperator = 'operator',
}

export const PayoutOrderStatusDetails = {
  ...PayoutDisputeStatusDetails,
  ...PayoutCancelStatusDetails,
  ...PayoutRejectStatusDetails,
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type PayoutOrderStatusDetails =
  | PayoutDisputeStatusDetails
  | PayoutCancelStatusDetails
  | PayoutRejectStatusDetails;
