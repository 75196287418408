import { Switch } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MutateOptions } from 'react-query';

import { RequisitesStatus } from 'enums';
import { TranslationNamespace } from 'i18n';

type Props = {
  initialStatus: RequisitesStatus;
  updateStatus: (
    status: RequisitesStatus,
    options: MutateOptions<any, any, any, any>,
  ) => void;
  readOnly: boolean;
};

export const RequisitesStatusToggle: React.FC<Props> = ({
  initialStatus,
  updateStatus,
  readOnly,
}: Props) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.requisites.requisites_list',
  });

  const [status, setStatus] = useState(initialStatus);

  const isActive = useMemo(() => status === RequisitesStatus.Active, [status]);

  const changeStatusTitle = useMemo(() => {
    if (status === RequisitesStatus.Active) {
      return t('actions.deactivate');
    } else if (status === RequisitesStatus.Inactive) {
      return t('actions.activate');
    }
  }, [status, t]);

  const oppositeStatus = useMemo(() => {
    if (status === RequisitesStatus.Active) {
      return RequisitesStatus.Inactive;
    } else if (status === RequisitesStatus.Inactive) {
      return RequisitesStatus.Active;
    }
  }, [status]);

  const handleStatusChange = useCallback(() => {
    if (oppositeStatus) {
      updateStatus(oppositeStatus, {
        onSuccess: () => {
          setStatus(oppositeStatus);
        },
      });
    }
  }, [oppositeStatus, updateStatus]);

  return (
    <Switch
      title={changeStatusTitle}
      checked={isActive}
      onChange={handleStatusChange}
      disabled={readOnly}
    />
  );
};
