import { Button, Link, Stack } from '@mui/material';
import { includes } from 'lodash';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CallbackUrlStatusLabel,
  CloseDialogResult,
  ConfirmButton,
  ContactInfo,
  CopyText,
  DataWrapper,
  DescriptionRow,
  Money,
  OrderAgentCashback,
  PayoutOrderStatusLabel,
  RequisitesInfo,
} from 'components';
import {
  OrderType,
  PayoutOrderStatus,
  PayoutOrderStatusDetails,
  WebhookMethod,
} from 'enums';
import { useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import {
  PayoutOrder,
  PayoutOrderDispute,
  PayoutOrderReject,
  ResolvePayoutOrderDispute,
} from 'types';
import { orderUtils } from 'utils';

import { DisputeDialog } from '../DisputeDialog';
import { PayoutOrderStatusHistory } from '../PayoutOrderStatusHistory';
import { ReceiptsModal } from '../ReceiptsModal';
import { PayoutOrderReceiptsValidation } from '../ReceiptsValidation';
import { RejectDialog } from '../RejectDialog';
import { ResolveDisputeDialog } from '../ResolveDisputeDialog';

type Props = {
  order: PayoutOrder;
  loading?: boolean;
  onAccept: () => void;
  onDispute: (data: PayoutOrderDispute) => void;
  onCancelledToDispute: () => void;
  onCancelCompleted: () => void;
  onResolveDispute: (data: ResolvePayoutOrderDispute) => void;
  onConfirm: () => void;
  onReject: (data: PayoutOrderReject) => void;
};

export const PayoutOrderDetails: React.FC<Props> = ({
  order,
  loading,
  onAccept,
  onDispute,
  onCancelledToDispute,
  onCancelCompleted,
  onResolveDispute,
  onConfirm,
  onReject,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders',
  });
  const { t: tColumns } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders.table.columns',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const [receiptsModalOpen, setReceiptsModalOpen] = useState(false);
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
  const [disputeDialogOpen, setDisputeDialogOpen] = useState(false);
  const [resolveDisputeDialogOpen, setResolveDisputeDialogOpen] =
    useState(false);

  const { isAdmin, isTrader, isTechOperator, isMerchant, isManager } =
    useUser();

  const showTraderSensitiveInfo = useMemo(
    () =>
      ![PayoutOrderStatus.Requisites, PayoutOrderStatus.TraderAccept].includes(
        order.status,
      ) || !isTrader,
    [order, isTrader],
  );

  const {
    isRequisitesStatus,
    isTraderAcceptStatus,
    isTraderPaymentStatus,
    isDisputeStatus,
    isCancelledStatus,
    isTraderAssigned,
    isCompletedStatus,
  } = useMemo(
    () => ({
      isRequisitesStatus: order?.status === PayoutOrderStatus.Requisites,
      isTraderAcceptStatus: order?.status === PayoutOrderStatus.TraderAccept,
      isTraderPaymentStatus: order?.status === PayoutOrderStatus.TraderPayment,
      isDisputeStatus: order?.status === PayoutOrderStatus.Dispute,
      isCancelledStatus: order?.status === PayoutOrderStatus.Cancelled,
      isTraderAssigned: !!order?.traderId,
      isCompletedStatus: order?.status === PayoutOrderStatus.Completed,
    }),
    [order],
  );

  const handleReceiptsClick = useCallback(() => {
    setReceiptsModalOpen(true);
  }, []);

  const handleReceiptsModalClose = useCallback(() => {
    setReceiptsModalOpen(false);
  }, []);

  const handleRejectClick = useCallback(() => {
    setRejectDialogOpen(true);
  }, []);

  const handleRejectDialogClose = useCallback(
    (result: CloseDialogResult<PayoutOrderReject>) => {
      if (result.ok && result.data) {
        onReject?.(result.data);
      }
      setRejectDialogOpen(false);
    },
    [onReject],
  );

  const handleDisputeClick = useCallback(() => {
    setDisputeDialogOpen(true);
  }, []);

  const handleDisputeDialogClose = useCallback(
    (result: CloseDialogResult<PayoutOrderDispute>) => {
      if (result.ok && result.data) {
        onDispute?.(result.data);
      }
      setDisputeDialogOpen(false);
    },
    [onDispute],
  );

  const handleResolveDisputeClick = useCallback(() => {
    setResolveDisputeDialogOpen(true);
  }, []);

  const handleResolveDisputeDialogClose = useCallback(
    (result: CloseDialogResult<ResolvePayoutOrderDispute>) => {
      if (result.ok && result.data) {
        onResolveDispute?.(result.data);
      }
      setResolveDisputeDialogOpen(false);
    },
    [onResolveDispute],
  );

  return (
    <Fragment>
      <DataWrapper isLoading={loading}>
        <Stack direction="column" spacing={6}>
          <Stack direction="column" spacing={3}>
            <DescriptionRow title={tColumns('id')} value={order.id} canCopy />
            <DescriptionRow
              title={tColumns('status')}
              value={
                <Fragment>
                  <PayoutOrderStatusLabel status={order?.status} />
                  {order.statusDetails && (
                    <div>
                      {orderUtils.getPayoutStatusDetailsLabel(
                        order.statusDetails,
                      )}
                    </div>
                  )}
                </Fragment>
              }
            />
            {showTraderSensitiveInfo && (
              <DescriptionRow
                title={tColumns('amount')}
                value={
                  <Fragment>
                    <Money
                      value={order.amount}
                      fiatCurrencyId={order.fiatCurrencyId}
                      symbol
                    />
                    <div>
                      <Money
                        value={order.assetCurrencyAmount}
                        assetCurrencyId={order.assetCurrencyId}
                        symbol
                      />
                      {' ('}
                      <Money
                        value={order.currencyRate}
                        fiatCurrencyId={order.fiatCurrencyId}
                        symbol
                      />
                      {')'}
                    </div>
                  </Fragment>
                }
              />
            )}
            {isManager && (
              <DescriptionRow
                title={tColumns('customer')}
                value={
                  <Fragment>
                    {order.customerId && <CopyText text={order.customerId} />}
                    <div>{order.customerName}</div>
                    <ContactInfo
                      phone={order.customerPhone}
                      email={order.customerEmail}
                    />
                  </Fragment>
                }
              />
            )}
            {showTraderSensitiveInfo && (
              <DescriptionRow
                title={tColumns('customer_requisites')}
                value={
                  <RequisitesInfo
                    cardholder={order.customerCardholder}
                    bankId={order.bankId}
                    paymentTypeId={order.paymentTypeId}
                    cardInfo={order.customerCard}
                    phone={order.customerPhone}
                    swiftBic={order.customerSwiftBic}
                    bic={order.customerBic}
                    idCard={order.customerIdCard}
                    beneficiaryName={order.customerBeneficiaryName}
                    accountNumber={order.customerAccountNumber}
                    email={order.customerEmail}
                    expirationDate={order.customerExpirationDate}
                  />
                }
              />
            )}
            {(isManager || isTrader) && order.requisites && (
              <DescriptionRow
                title={tColumns('trader_requisites')}
                value={
                  <RequisitesInfo
                    bankId={order.requisites.bankId}
                    paymentTypeId={order.requisites.paymentTypeId}
                    fiatCurrencyId={order.requisites.fiatCurrencyId}
                  />
                }
              />
            )}
            {isManager && (
              <Fragment>
                <DescriptionRow
                  title={tColumns('shop')}
                  value={
                    <Fragment>
                      <CopyText text={order.shopId} />
                      <div>{order.shop?.name}</div>
                    </Fragment>
                  }
                />
                <DescriptionRow
                  title={tColumns('merchant')}
                  value={
                    <Fragment>
                      <CopyText text={order.merchantId} />
                      <div>{order.merchant.user?.name}</div>
                    </Fragment>
                  }
                />
              </Fragment>
            )}

            {(isManager || isMerchant) && (
              <Fragment>
                {order.externalOrderId && (
                  <DescriptionRow
                    title={tColumns('external_order_id')}
                    value={
                      <Fragment>
                        <CopyText text={order.externalOrderId} />
                      </Fragment>
                    }
                  />
                )}
                {order.callbackUrl && (
                  <DescriptionRow
                    title={tColumns('callback')}
                    value={
                      <Fragment>
                        <div className="tw-flex">
                          <CopyText
                            className="tw-break-all"
                            text={order.callbackUrl}
                            label={`[${
                              order.callbackMethod || WebhookMethod.Post
                            }] ${order.callbackUrl}`}
                          />
                        </div>
                        {order.callbackUrlStatus && (
                          <div>
                            <CallbackUrlStatusLabel
                              status={order.callbackUrlStatus}
                            />
                          </div>
                        )}
                      </Fragment>
                    }
                  />
                )}
              </Fragment>
            )}
            {isMerchant && (
              <DescriptionRow
                title={tColumns('payout_shop_amount')}
                value={
                  <Money
                    value={order.shopAmount}
                    assetCurrencyId={order.assetCurrencyId}
                    symbol
                  />
                }
              />
            )}
            {!isMerchant && showTraderSensitiveInfo && (
              <DescriptionRow
                title={tColumns('trader_compensation')}
                value={
                  <Money
                    value={order.traderCompensation}
                    assetCurrencyId={order.assetCurrencyId}
                    symbol
                  />
                }
              />
            )}
            {isManager && (
              <Fragment>
                {!!order?.shopFee && (
                  <DescriptionRow
                    title={tColumns('shop_fee')}
                    value={
                      <Money
                        value={order.shopFee}
                        assetCurrencyId={order.assetCurrencyId}
                        symbol
                      />
                    }
                  />
                )}
              </Fragment>
            )}
            {(isAdmin || isTechOperator) && (
              <OrderAgentCashback order={order} operation={OrderType.Payout} />
            )}
            {(!isTrader || (!isTraderAcceptStatus && !isRequisitesStatus)) && (
              <DescriptionRow
                title={tColumns('receipts')}
                value={
                  <div>
                    <Link
                      component="button"
                      variant="body2"
                      underline="none"
                      onClick={handleReceiptsClick}
                    >
                      {tCommon('buttons.open')}
                    </Link>
                  </div>
                }
              />
            )}
            {isManager &&
              (isDisputeStatus || isCancelledStatus || isCompletedStatus) && (
                <DescriptionRow
                  title={tColumns('validation')}
                  value={
                    <PayoutOrderReceiptsValidation order={order} labelled />
                  }
                />
              )}
            {isManager && (
              <PayoutOrderStatusHistory order={order} loading={loading} />
            )}
          </Stack>

          {isTrader &&
            (isTraderAcceptStatus || isRequisitesStatus) &&
            !orderUtils.isStatusTimeoutOver(order) && (
              <Stack direction="column" spacing={3}>
                {isTraderAcceptStatus && (
                  <Button
                    variant="outlined"
                    color="error"
                    disabled={loading}
                    onClick={handleRejectClick}
                    fullWidth
                  >
                    {tCommon('buttons.reject')}
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={loading}
                  onClick={onAccept}
                  fullWidth
                >
                  {tCommon('buttons.accept')}
                </Button>
              </Stack>
            )}

          {isTrader && isTraderPaymentStatus && (
            <Stack direction="column" spacing={3}>
              <Button
                variant="outlined"
                color="error"
                disabled={loading}
                onClick={handleDisputeClick}
                fullWidth
              >
                {t('buttons.dispute')}
              </Button>
              <Button
                variant="outlined"
                color="error"
                disabled={loading}
                onClick={handleRejectClick}
                fullWidth
              >
                {tCommon('buttons.reject')}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                disabled={loading}
                onClick={onConfirm}
                fullWidth
              >
                {tCommon('buttons.confirm')}
              </Button>
            </Stack>
          )}
          {isTrader &&
            isDisputeStatus &&
            includes(
              [
                PayoutOrderStatusDetails.DisputePaymentTimeout,
                PayoutOrderStatusDetails.DisputePaymentFailed,
                PayoutOrderStatusDetails.DisputeInvalidRequisites,
              ],
              order?.statusDetails,
            ) && (
              <Stack direction="column" spacing={3}>
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={loading}
                  onClick={handleResolveDisputeClick}
                  fullWidth
                >
                  {t('buttons.resolve_dispute')}
                </Button>
              </Stack>
            )}
          {isManager && isDisputeStatus && (
            <Stack direction="column" spacing={3}>
              <Button
                variant="outlined"
                color="primary"
                disabled={loading}
                onClick={handleResolveDisputeClick}
                fullWidth
              >
                {t('buttons.resolve_dispute')}
              </Button>
            </Stack>
          )}
          {isManager && isCancelledStatus && isTraderAssigned && (
            <Stack direction="column" spacing={3}>
              <ConfirmButton
                variant="outlined"
                color="primary"
                disabled={loading}
                onConfirm={onCancelledToDispute}
                fullWidth
              >
                {t('buttons.dispute')}
              </ConfirmButton>
            </Stack>
          )}
          {isManager && isCompletedStatus && (
            <Stack direction="column" spacing={3}>
              <ConfirmButton
                variant="outlined"
                color="error"
                disabled={loading}
                onConfirm={onCancelCompleted}
                fullWidth
              >
                {tCommon('buttons.cancel')}
              </ConfirmButton>
            </Stack>
          )}
        </Stack>
      </DataWrapper>
      <ReceiptsModal
        open={receiptsModalOpen}
        order={order}
        onClose={handleReceiptsModalClose}
      />
      <RejectDialog
        open={rejectDialogOpen}
        order={order}
        onClose={handleRejectDialogClose}
      />
      <DisputeDialog
        open={disputeDialogOpen}
        order={order}
        onClose={handleDisputeDialogClose}
      />
      <ResolveDisputeDialog
        open={resolveDisputeDialogOpen}
        order={order}
        disabled={isTrader}
        onClose={handleResolveDisputeDialogClose}
      />
    </Fragment>
  );
};
