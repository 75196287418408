import { Stack } from '@mui/material';
import { AxiosError } from 'axios';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { TextField } from 'formik-mui';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import * as Yup from 'yup';

import { walletsApi } from 'api';
import {
  CloseDialogResult,
  DataWrapper,
  Dialog,
  NetworkSelect,
} from 'components';
import { NEW_ID } from 'constants/common.constants';
import { QueryKey } from 'enums';
import { useMutation } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { Wallet } from 'types';
import { validationUtils } from 'utils';

type Props = {
  open: boolean;
  walletId: string;
  onClose: () => void;
};

type Values = Pick<Wallet, 'name' | 'address' | 'network'>;

export const WalletDialog: React.FC<Props> = ({ open, walletId, onClose }) => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.assets.platform.wallets.wallet_dialog',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common, {});

  const isNew = useMemo(() => walletId === NEW_ID, [walletId]);

  const title = useMemo(
    () => t(isNew ? 'create_title' : 'edit_title'),
    [isNew, t],
  );

  const initialState: Values = useMemo(
    () => ({
      name: '',
      address: '',
      network: '',
    }),
    [],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required(tCommon('errors.required')),
        address: Yup.string().required(tCommon('errors.required')),
        network: Yup.string().required(tCommon('errors.required')),
      }),
    [tCommon],
  );

  const [initialValues, setInitialValues] = useState<Values>(initialState);

  const queryClient = useQueryClient();

  const queryResult = useQuery(
    [QueryKey.Wallets, walletId],
    () => walletsApi.findOne(walletId),
    {
      enabled: !!walletId && !isNew,
      onSuccess: (data) =>
        setInitialValues({
          name: data.name,
          address: data.address,
          network: data.network,
        }),
      keepPreviousData: true,
    },
  );

  const { mutate: createWallet } = useMutation<
    Wallet,
    AxiosError,
    Partial<Wallet>
  >(walletsApi.create);

  const { mutate: updateWallet } = useMutation<
    Wallet,
    AxiosError,
    { id: string; wallet: Values }
  >(walletsApi.update);

  const handleSubmit = useCallback(
    (wallet: Values, formikHelpers: FormikHelpers<Values>) => {
      const options = {
        onSuccess: () => {
          queryClient.invalidateQueries(QueryKey.Wallets);
          onClose();
          formikHelpers.resetForm({ values: initialState });
        },
        onError: (error: AxiosError) =>
          formikHelpers.setErrors(validationUtils.getFormErrors(error)),
        onSettled: () => formikHelpers.setSubmitting(false),
      };

      formikHelpers.setSubmitting(true);
      if (isNew) {
        createWallet(wallet, options);
      } else {
        updateWallet({ id: walletId, wallet }, options);
      }
    },
    [
      isNew,
      queryClient,
      onClose,
      initialState,
      createWallet,
      updateWallet,
      walletId,
    ],
  );

  const handleClose = useCallback(
    ({ ok }: CloseDialogResult<Values>, formik: FormikProps<Values>) => {
      if (ok) {
        formik.submitForm();
        return;
      }

      onClose();
      formik.resetForm({ values: initialState });
    },
    [initialState, onClose],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(formik) => (
        <Dialog
          maxWidth="sm"
          open={open}
          title={title}
          okLabel={tCommon('buttons.save')}
          onClose={(data) => handleClose(data, formik)}
        >
          <DataWrapper queryResult={!isNew ? queryResult : undefined}>
            <Form>
              <Stack spacing={4}>
                <Field
                  component={TextField}
                  label={t('fields.name')}
                  name="name"
                  size="small"
                  required
                  fullWidth
                />
                <Field
                  component={TextField}
                  label={t('fields.address')}
                  name="address"
                  size="small"
                  required
                  fullWidth
                />
                <NetworkSelect required name="network" />
              </Stack>
            </Form>
          </DataWrapper>
        </Dialog>
      )}
    </Formik>
  );
};
