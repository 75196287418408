import React from 'react';
import { useQuery } from 'react-query';

import { shopsApi } from 'api';
import { MyAssetBalanceHistory } from 'components';
import { QueryKey } from 'enums';
import { assetBalanceHistoryUtils } from 'utils';

export const MerchantBalanceHistoryPage: React.FC = () => {
  const { data: shops = [] } = useQuery(QueryKey.Shops, shopsApi.getAllMy);
  return (
    <MyAssetBalanceHistory
      additionalFilters={assetBalanceHistoryUtils.getShopFilters(shops)}
      additionalColumns={assetBalanceHistoryUtils.getMerchantColumns(shops)}
    />
  );
};
