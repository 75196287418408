import moment from 'moment';
import React from 'react';

import { Timer, PayoutOrderStatusLabel } from 'components';
import { SHOW_PAYOUT_TIMER_WHEN_LESS_BY_STATUS } from 'constants/order.constants';
import { PayoutOrder } from 'types';

type Props = {
  order: PayoutOrder;
};

export const PayoutOrderStatusColumn: React.FC<Props> = ({ order }) => (
  <div>
    {SHOW_PAYOUT_TIMER_WHEN_LESS_BY_STATUS[order.status] &&
      (order.statusTimeoutAt || order.maxTimeoutAt) && (
        <Timer
          endTime={(order.statusTimeoutAt && order.maxTimeoutAt
            ? moment(
                moment.min([
                  moment(order.statusTimeoutAt),
                  moment(order.maxTimeoutAt),
                ]),
              )
            : order.statusTimeoutAt
            ? moment(order.statusTimeoutAt)
            : moment(order.maxTimeoutAt)
          ).toDate()}
          showWhenLess={SHOW_PAYOUT_TIMER_WHEN_LESS_BY_STATUS[order.status]}
        />
      )}
    <div>
      <PayoutOrderStatusLabel status={order.status} />
    </div>
  </div>
);
