import { AxiosError } from 'axios';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { TextField } from 'formik-mui';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { fundsRequestsApi } from 'api';
import { Math } from 'classes';
import {
  CloseDialogResult,
  CloseDialogHandler,
  Dialog,
  FormikNumericField,
} from 'components';
import { WITHDRAWAL_FEE_AMOUNT } from 'constants/funds-requests.constants';
import { Network } from 'enums';
import { useCurrencies, useMutation } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { Asset, CreateFundsRequest, FundsRequest } from 'types';
import { validationUtils } from 'utils';

type Props = {
  open: boolean;
  asset?: Asset;
  onClose: CloseDialogHandler;
};

type Values = {
  network: Network;
  address: string;
  amount: number;
  fee: number;
  paymentAmount: number;
  hash?: string;
};

// TODO: rename to withdrawal dialog
export const FundsRequestDialog: React.FC<Props> = ({
  open,
  asset,
  onClose,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.funds_request_dialog',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common, {});
  const { getAssetCurrencySymbol } = useCurrencies();

  const { mutate: create, reset } = useMutation<
    FundsRequest,
    AxiosError,
    CreateFundsRequest
  >(fundsRequestsApi.createWithdrawal);

  const initialValues: Values = useMemo(
    () => ({
      network: Network.TRC20,
      address: '',
      amount: 0,
      fee: WITHDRAWAL_FEE_AMOUNT,
      paymentAmount: 0,
    }),
    [],
  );

  const renderRow = useCallback(
    (label: string, value?: string | number | React.ReactElement) => (
      <div className="tw-flex tw-my-2">
        <div className="tw-mr-1 tw-font-bold">{`${label}:`}</div>
        <div>{value}</div>
      </div>
    ),
    [],
  );

  const resetComponent = useCallback(
    (formik: FormikProps<Values>) => {
      reset();
      formik.resetForm();
    },
    [reset],
  );

  const handleSubmit = useCallback(
    (values: Values, formikHelpers: FormikHelpers<Values>) => {
      formikHelpers.setSubmitting(true);
      if (asset) {
        create(
          {
            assetCurrencyId: asset.assetCurrencyId,
            assetId: asset.id,
            ...values,
          },
          {
            onSuccess: () => {
              onClose({ ok: true });
              formikHelpers.resetForm();
            },
            onError: (error: AxiosError) => {
              formikHelpers.setErrors(validationUtils.getFormErrors(error));
            },
            onSettled: () => {
              formikHelpers.setSubmitting(false);
            },
          },
        );
      }
    },
    [asset, create, onClose],
  );
  const handleClose = useCallback(
    ({ ok }: CloseDialogResult<Values>, formik: FormikProps<Values>) => {
      if (!ok) {
        resetComponent(formik);
        onClose({ ok });
        return;
      }
      if (ok) {
        formik.submitForm();
        return;
      }
    },
    [onClose, resetComponent],
  );

  const handleAmountChange = useCallback(
    (value: number, formik: FormikProps<Values>) => {
      const math = new Math(value || 0);
      math.minus(formik.values.fee);
      formik.setFieldValue('paymentAmount', math.value);
    },
    [],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        address: Yup.string().required(tCommon('errors.required')),
        amount: Yup.number()
          .required(tCommon('errors.required'))
          .moreThan(initialValues.fee, tCommon('errors.more_than_fee')),
      }),
    [initialValues.fee, tCommon],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(formik) => (
        <Dialog
          open={open}
          title={t('withdrawal.title')}
          okLabel={t('ok_label')}
          onClose={(data) => handleClose(data, formik)}
        >
          <div>
            {renderRow(t('fields.network'), asset?.requisites?.network)}
            {renderRow(t('fields.address'), asset?.requisites?.address)}
            <Form className="tw-grid tw-gap-4">
              <Field
                component={TextField}
                name="address"
                label={t('fields.your_address')}
                size="small"
                required
              />
              <FormikNumericField
                label={t('fields.amount')}
                name="amount"
                allowNegative={false}
                suffix={getAssetCurrencySymbol(asset?.assetCurrencyId)}
                required
                onChangeHandler={(value: number) =>
                  handleAmountChange(value, formik)
                }
              />
              <FormikNumericField
                label={t('fields.fee')}
                name="fee"
                allowNegative={false}
                suffix={getAssetCurrencySymbol(asset?.assetCurrencyId)}
                disabled
              />
              <FormikNumericField
                label={t('fields.withdrawal_amount')}
                name="paymentAmount"
                suffix={getAssetCurrencySymbol(asset?.assetCurrencyId)}
                disabled
              />
            </Form>
          </div>
        </Dialog>
      )}
    </Formik>
  );
};
