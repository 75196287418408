import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Stack, IconButton } from '@mui/material';
import i18next from 'i18next';

import { DataGridColumnDefinition } from '../types';

type Options<T> = {
  handleEdit?: (item: T) => void;
  handleRemove?: (item: T) => void;
  children?: (item: T) => React.ReactNode | React.ReactNode[];
};

export function getActionsColumn<T>({
  handleEdit,
  handleRemove,
  children,
}: Options<T>): DataGridColumnDefinition<T> {
  const { t } = i18next;
  if (!handleEdit && !handleRemove && !children) {
    return null as unknown as DataGridColumnDefinition<T>;
  }
  return {
    header: t('columns.actions'),
    valueClassName: 'tw-w-[120px]',
    valueGetter: (item) => (
      <Stack direction="row" spacing={1}>
        {children?.(item)}
        {handleEdit && (
          <IconButton
            color="primary"
            onClick={(e) => {
              e.stopPropagation();
              handleEdit(item);
            }}
          >
            <EditIcon />
          </IconButton>
        )}
        {handleRemove && (
          <IconButton
            color="error"
            onClick={(e) => {
              e.stopPropagation();
              handleRemove(item);
            }}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Stack>
    ),
  };
}
